import React from "react";
import { useSelector } from "react-redux";
import { ROLES } from "../../utilities/enumerations";
import AdminProfileEdit from "./Admin/AdminProfileEdit";
import PracticeProfileEdit from "./Practice/PracticeProfileEdit";
import LocumProfileEdit from "./Locum/LocumProfileEdit";

const Profile = () => {
  const account = useSelector((state) => state.account);

  return (
    <div id="profile">
      {account.role === ROLES.admin &&
        account.profile_data &&
        account.profile_data.basic_info &&
        account.profile_data &&
        account.profile_data.basic_info && <AdminProfileEdit />}
      {account.role === ROLES.locum && <LocumProfileEdit />}
      {account.role === ROLES.practice && <PracticeProfileEdit />}
    </div>
  );
};

export default Profile;
