import { combineReducers } from "redux";
import accountSlice from "./modules/accountSlice.js";
import bookingSlice from "./modules/bookingSlice.js";
import userSlice from "./modules/userSlice";
import backDropSlice from "./modules/backDropSlice";
import modalSlice from "./modules/modalSlice";
import paymentSlice from "./modules/paymentSlice.js";
import alertSlice from "./modules/alertSlice.js";
import chatSlice from "./modules/chatSlice.js";
import contentSlice from "./modules/contentSlice.js";
import settingSlice from "./modules/settingSlice.js";
import browseStepsSlice from "./modules/browseStepsSlice.js";
import subscriptionSlice from "./modules/subscriptionSlice.js";

// Combine all your slices into a root reducer
const rootReducer = combineReducers({
    account: accountSlice,
    alert: alertSlice,
    backdrop: backDropSlice,
    bookings: bookingSlice,
    user: userSlice,
    modal: modalSlice,
    payment: paymentSlice,
    chat: chatSlice,
    content: contentSlice,
    settings: settingSlice,
    browseSteps: browseStepsSlice,
    subscription: subscriptionSlice,
});

export default rootReducer;
