import { createSlice } from "@reduxjs/toolkit";
import { SetBackDrop } from "./backDropSlice";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { ResetAvailabilitiesSliceState } from "./bookingSlice";
import { ResetChatSliceState } from "./chatSlice";
import { ResetSubscriptionSliceState } from "./subscriptionSlice";
import { ResetModalSliceState } from "./modalSlice";
import { ResetUserSliceState } from "./userSlice";
import { ResetAlertSliceState } from "./alertSlice";
import { ResetPaymentSliceState } from "./paymentSlice";
import { ResetBrowseSteps } from "./browseStepsSlice";
import { httpGet, httpPost, httpPut } from "../../services/httpService";

const initialState = {
  a_is_processing: false,
  role: "",
  user_token: "",
  userNotifications: [],
  userName: "",
  userId: "",
  email: "",
  message: "",
  isSignedUp: false,
  isEmailSent: false,
  isPasswordReset: false,
  is_logged_in: false,
  isProfileComplete: false,
  isAccDetailsAdded: false,
  fullName: "",
  prefix: "",
  mobile_number: "",
  profileImagePath: "",
  profileImageName: "",
  profile_data: {},
  user_notifications_count: 0,
  user_chat_id: "",
  user_chat_token: "",
  new_message: false,
  total_unread_count: 0,
  subscription_lookup_data: {},
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    signup(state, { payload }) {
      return {
        ...state,
        isSignedUp: true,
      };
    },
    login(state, { payload }) {
      return {
        ...state,
        user_token: payload.data.token,
        is_logged_in: true,
        userName: payload.data.user_name,
        userId: payload.data.user_id,
        email: payload.data.email,
        role: payload.data.role,
        fullName: payload.data.full_name,
        prefix: payload.data.prefix,
        phone: payload.data.phone,
        profileImagePath: payload.data.profileImagePath,
        profileImageName: payload.data.profileImageName,
        user_chat_id: payload.data.user_chat_id,
        user_chat_token: payload.data.user_chat_token,
        profile_data: {
          basic_info: payload.data.basic_info,
          detailed_info: payload.data.detailed_info,
        },
        subscription_lookup_data: payload.data.subscription_status ? payload.data.subscription_status : {}
      };
    },
    setUserData(state, { payload }) {
      return {
        ...state,
      };
    },
    ResetAccountSliceState(state, { payload }) {
      // localStorage.removeItem("user_details");
      return initialState;
    },
    updateProfile(state, { payload }) {
      if (payload.data.by_admin) {
        return {
          ...state,
          profile_data: payload.data,
        };
      } else {
        return {
          ...state,
          userName: payload.data.basic_info.user_name,
          email: payload.data.basic_info.email,
          fullName: payload.data.basic_info.full_name,
          prefix: payload.data.basic_info.prefix,
          mobile_number: payload.data.basic_info.mobile_number,
          profile_data: {
            basic_info: payload.data.basic_info,
            detailed_info: payload.data.detailed_info,
          },
        };
      }
    },
    deleteAccount(state, { payload }) {
      return {
        ...state,
        message: payload.message,
        user_token: "",
        is_logged_in: false,
        userName: "",
        userId: "",
        email: "",
        role: "",
      };
    },
    resetPassword(state, { payload }) {
      return {
        ...state,
        isPasswordReset: true,
      };
    },
    forgotPassword(state, { payload }) {
      return {
        ...state,
        isEmailSent: true,
      };
    },
    setUserNotifications(state, { payload }) {
      return {
        ...state,
        userNotifications: payload.data.notifications,
        user_notifications_count: payload.data.notifications_count,
      };
    },
    readNotification: (state, { payload }) => {
      state.userNotifications = state.userNotifications.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            is_read: 1,
          };
        }
        return item;
      });
      state.user_notifications_count =
        state.userNotifications && state.userNotifications.length > 0
          ? state.userNotifications.filter((item) => item.is_read === 0).length
          : 0;
    },
    readAllNotifications: (state, { payload }) => {
      state.userNotifications = state.userNotifications.map((item) => {
        return {
          ...item,
          is_read: 1,
        };
      });
      state.user_notifications_count = 0;
    },
    SetAccountSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
    uploadProfileImage(state, { payload }) {
      return {
        ...state,
        profileImagePath: payload.data.imagePath,
        profileImageName: payload.data.imageName,
      };
    },
    getAccountProfile(state, { payload }) {
      return {
        ...state,
        profile_data: payload.data,
      };
    },
    UpdateSubscriptionLookupData(state, { payload }) {
      return {
        ...state,
        subscription_lookup_data: payload.data ? payload.data : {},
      };
    },
    updateLocalStorageData(state, { payload }) {
      localStorage.removeItem("user_details");
      const userDetails = {};
      localStorage.setItem("user_details", JSON.stringify(userDetails));
    },
    UpdateSettingsData(state, { payload }) {
      console.log(payload.data)
      if (payload.data && payload.data.length > 0) {
        if (payload.data[0].notification_type === "email_notifications") {
          state.profile_data.basic_info.email_notifications = payload.data[0].value ? 1 : 0
        }
      }
    },
  },
});

export const {
  signup,
  login,
  ResetAccountSliceState,
  updateProfile,
  deleteAccount,
  resetPassword,
  forgotPassword,
  setUserNotifications,
  readNotification,
  readAllNotifications,
  setUserData,
  SetAccountSliceBits,
  uploadProfileImage,
  getAccountProfile,
  updateLocalStorageData,
  UpdateSubscriptionLookupData,
  UpdateSettingsData
} = accountSlice.actions;
export default accountSlice.reducer;

//----------------------------------------------------------------
// Common action creators
const showSuccessAndDispatch = (
  dispatch,
  action = null,
  response,
  showToast = false
) => {
  showBackDropAndLoader(dispatch, false, false); // Hide the backdrop on success
  if (action) {
    dispatch(action(response.data));
  }
  if (showToast) {
    SHOW_SUCCESS_TOAST(response);
  }
};

const showErrorAndLog = (dispatch, error, action = null, showToast = true) => {
  showBackDropAndLoader(dispatch, false, false); // Hide the backdrop on success
  console.log(error);
  if (action) {
    dispatch(action(error));
  }
  if (showToast) {
    SHOW_ERROR_TOAST(error);
  }
};

const showBackDropAndLoader = (
  dispatch,
  setbackdrop = false,
  setLoader = false
) => {
  dispatch(SetBackDrop(setbackdrop));
  dispatch(
    SetAccountSliceBits({
      bitToSet: "a_is_processing",
      value: setLoader,
    })
  );
};

// ------------------------Login------------------------ //
export function Login(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/login`,
        {
          user_name: payload.user_name,
          password: payload.password,
        }
      );
      showSuccessAndDispatch(dispatch, login, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------Logout------------------------ //
export function Logout(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/logout`,
        {},
        {
          bearer_token: payload,
        }
      );
      dispatch(ResetAccountSliceState());
      dispatch(ResetAvailabilitiesSliceState());
      dispatch(ResetChatSliceState());
      dispatch(ResetSubscriptionSliceState());
      dispatch(ResetModalSliceState());
      dispatch(ResetUserSliceState());
      dispatch(ResetAlertSliceState());
      dispatch(ResetPaymentSliceState());
      dispatch(ResetBrowseSteps());
      showSuccessAndDispatch(dispatch, null, response, true);
    } catch (error) {
      dispatch(ResetAccountSliceState());
      dispatch(ResetAvailabilitiesSliceState());
      dispatch(ResetChatSliceState());
      dispatch(ResetSubscriptionSliceState());
      dispatch(ResetModalSliceState());
      dispatch(ResetUserSliceState());
      dispatch(ResetAlertSliceState());
      dispatch(ResetPaymentSliceState());
      dispatch(ResetBrowseSteps());
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------Signup------------------------ //
export function Signup(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/signup`,
        {
          ...payload,
        }
      );
      showSuccessAndDispatch(dispatch, signup, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------UpdateProfilePassword------------------------ //
export function UpdateProfilePassword(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/update_profile_password`,
        {
          old_password: payload.old_password,
          new_password: payload.new_password,
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, null, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------UpdateProfile------------------------ //
export function UpdateProfile(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/update_profile`,
        {
          ...payload,
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, updateProfile, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------DeleteAccount------------------------ //
export function DeleteAccount(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/delete_account`,
        {
          password: payload.password,
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, deleteAccount, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------ResetPassword------------------------ //
export function ResetPassword(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/change_password`,
        {
          password: payload.password,
          c_password: payload.password,
          token: payload.token,
        }
      );
      showSuccessAndDispatch(dispatch, resetPassword, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// ------------------------ForgotPassword------------------------ //
export function ForgotPassword(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/forgot_password`,
        {
          email: payload.email,
        }
      );
      showSuccessAndDispatch(dispatch, forgotPassword, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// UserNotifications -----------------------------------------------------------;
export function UserNotifications(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/user_notifications`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, setUserNotifications, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// Mark Read Notification ------------------------------------------------------;
export function ReadNotification(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      dispatch(readNotification(payload));
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/mark_read_notification`,
        {
          id: payload.id,
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, readNotification, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// Mark Read All Notification ------------------------------------------------------;
export function ReadAllNotifications(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      dispatch(readAllNotifications());
      const response = await httpPut(
        `${process.env.REACT_APP_API_URI}/api/mark_read_all_notification`,
        {},
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, readAllNotifications, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

// Upload profile picture
export function UploadProfilePicture(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/upload_img`,
        {
          ...payload.imageData
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, uploadProfileImage, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetProfileData(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/get_profile_data/${payload.user_id}`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getAccountProfile, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}
