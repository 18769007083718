import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  taskDetailsModalOpen: false,
  locumInfoModalOpen: false,
  mapModalOpen: false,
  reviewModalOpen: false,
  locum_review_modal_open: false,
  practiceReviewModalOpen: false,
  siteContentModalOpen: false,
  locum_info: {},
  locationData: {},
  taskInfo: {},
  reviewData: {},
  siteContentData: {},
  userLat: "",
  userLng: "",
  distanceRange: "",
  location: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    ResetModalSliceState(state, { payload }) {
      return initialState;
    },
    setTaskDetailsModal(state, { payload }) {
      return {
        ...state,
        taskDetailsModalOpen: payload.taskDetailsModalOpen,
        taskInfo: payload.task_data,
      };
    },
    setLocumInfoModal(state, { payload }) {
      return {
        ...state,
        locumInfoModalOpen: payload.locumInfoModalOpen,
        locum_info: payload.locum_info,
      };
    },
    setTaskMapModal(state, { payload }) {
      return {
        ...state,
        mapModalOpen: payload.mapModalOpen,
        locationData: payload.location_data,
      };
    },
    setReviewModal(state, { payload }) {
      state[payload.bitToSet] = payload.bit_value;
      state[payload.reviewModalParam] = payload.reviewModalData;
    },
    setUserLocationInfo(state, { payload }) {
      return {
        ...state,
        userLat: payload.userLat,
        userLng: payload.userLng,
        distanceRange: payload.distanceRange,
        location: payload.location,
        mapModalOpen: false,
        locationData: {},
      };
    },
    setSiteContentModal(state, { payload }) {
      return {
        ...state,
        siteContentModalOpen: payload.siteContentModalOpen,
        siteContentData: payload.siteContentData,
      };
    },
  },
});

export const {
  ResetModalSliceState,
  setTaskDetailsModal,
  setLocumInfoModal,
  setTaskMapModal,
  setReviewModal,
  setUserLocationInfo,
  setSiteContentModal,
} = modalSlice.actions;
export default modalSlice.reducer;

//----------------------------------------------------------------

export function SetTaskDetailsModal(payload) {
  return (dispatch) => {
    dispatch(setTaskDetailsModal(payload));
  };
}

export function SetLocumInfoModal(payload) {
  return (dispatch) => {
    dispatch(setLocumInfoModal(payload));
  };
}

export function SetTaskMapModal(payload) {
  return (dispatch) => {
    dispatch(setTaskMapModal(payload));
  };
}

export function SetReviewModal(payload) {
  return (dispatch) => {
    dispatch(setReviewModal(payload));
  };
}

export function SetUserLocationInfo(payload) {
  return (dispatch) => {
    dispatch(setUserLocationInfo(payload));
    toast.success("Location saved successfully.");
  };
}

export function SetSiteContentModal(payload) {
  return (dispatch) => {
    dispatch(setSiteContentModal(payload));
  };
}
