import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Label from "../Label/Label";
import "./style.scss";

const TextEditor = ({
  inputError,
  label,
  isRequired,
  inputValue,
  handleTextEditorChange,
}) => {
  return (
    <>
      <Label label={label} isRequired={isRequired}></Label>
      <ReactQuill
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
        ]}
        theme="snow"
        value={inputValue}
        onChange={handleTextEditorChange}
      />
      <small className="text-danger" style={{ fontSize: "0.75rem" }}>
        {inputError}
      </small>
    </>
  );
};
export default TextEditor;
