import { createSlice } from "@reduxjs/toolkit";
import { SetBackDrop } from "./backDropSlice";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { httpGet, httpPost } from "../../services/httpService";

const initialState = {
  publishedKey: "",
  secretKey: "",
  p_is_processing: false,
  is_payment_successful: false,
  stripe_balance_data: {},
  all_stripe_payments: [],
  stripe_payments_count: 0,
  practice_stripe_payments: [],
  practice_stripe_payments_count: 0,
  all_locum_payouts: [],
  allLocumPayoutsCount: 0,
  customer_payment_methods: [],
  hasLinkedStripeAccount: false,
  needStripeAccountVerification: false,
  stripeAccountVerificationUrl: "",
  payment_method_change_success: false,
  payment_method_delete_success: false,
  add_payment_method_success: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    ResetPaymentSliceState(state, { payload }) {
      return initialState;
    },
    SetPaymentSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
    updatePaymentStatus(state, { payload }) {
      return {
        ...state,
        is_payment_successful:
          payload.data.payment_status === "paid" ? true : false,
      };
    },
    setStripeBalanceInfo(state, { payload }) {
      return {
        ...state,
        stripe_balance_data: payload.data.balance_info,
      };
    },
    setAllStripePayments(state, { payload }) {
      return {
        ...state,
        all_stripe_payments: payload.data.all_stripe_payments,
        allPaymentsCount: payload.data.stripe_payments_count,
      };
    },
    setPracticeStripePayments(state, { payload }) {
      return {
        ...state,
        practice_stripe_payments: payload.data.practice_stripe_payments,
        practice_stripe_payments_count: payload.data.practice_stripe_payments_count,
      };
    },
    makePaymentMethodDefault(state, { payload }) {
      return {
        ...state,
        payment_method_change_success: true
      };
    },
    deletePaymentMethod(state, { payload }) {
      return {
        ...state,
        payment_method_delete_success: true
      };
    },
    addPaymentMethod(state, { payload }) {
      return {
        ...state,
        add_payment_method_success: true
      };
    },
    setPracticePaymentMethods(state, { payload }) {
      return {
        ...state,
        customer_payment_methods: payload.data.payment_methods
      };
    },
  },
});

export const {
  ResetPaymentSliceState,
  setPublishedKey,
  setSecretKey,
  SetPaymentSliceBits,
  clearStripeKeys,
  updatePaymentStatus,
  setStripeBalanceInfo,
  setAllStripePayments,
  linkStripeAccount,
  setAllLocumPayouts,
  stripeSubscription,
  makePaymentMethodDefault,
  deletePaymentMethod,
  addPaymentMethod,
  setPracticeStripePayments,
  setPracticePaymentMethods
} = paymentSlice.actions;
export default paymentSlice.reducer;

//----------------------------------------------------------------

// Common action creators
const showSuccessAndDispatch = (dispatch, action = null, response, showToast = false) => {
  showBackDropAndLoader(dispatch, false, false) // Hide the backdrop on success
  if (action) {
    dispatch(action(response.data));
  }
  if (showToast) {
    SHOW_SUCCESS_TOAST(response);
  }
};

const showErrorAndLog = (dispatch, error, action = null, showToast = true) => {
  showBackDropAndLoader(dispatch, false, false) // Hide the backdrop on success
  console.log(error);
  if (action) {
    dispatch(action(error));
  }
  if (showToast) {
    SHOW_ERROR_TOAST(error);
  }
};

const showBackDropAndLoader = (dispatch, setbackdrop = false, setLoader = false) => {
  dispatch(SetBackDrop(setbackdrop));
  dispatch(SetPaymentSliceBits({
    bitToSet: "p_is_processing",
    value: setLoader,
  }))
};

export function GetStripeBalanceInfo(payload) {
  return async (dispatch) => {
    try {
      const response = await httpGet(`${process.env.REACT_APP_API_URI}/api/stripe_balance`, {
        bearer_token: payload.user_token,
      });

      showSuccessAndDispatch(dispatch, setStripeBalanceInfo, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetPracticePaymentMethods(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false)
    try {
      const response = await httpGet(`${process.env.REACT_APP_API_URI}/api/practice_payment_methods`, {
        bearer_token: payload.user_token,
      });

      showSuccessAndDispatch(dispatch, setPracticePaymentMethods, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}


export function GetAllStripePayments(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false)
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/all_stripe_payments`,
        {
          status: payload.data?.status,
          practiceName: payload.data?.practiceName,
          rows_per_page: payload.data?.rows_per_page,
          start: payload.data?.start,
        },
        {
          bearer_token: payload.user_token,
        }
      );

      showSuccessAndDispatch(dispatch, setAllStripePayments, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetPracticeStripePayments(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false)
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/practice_stripe_payments`,
        {
          status: payload.data?.status,
          practiceName: payload.data?.practiceName,
          rows_per_page: payload.data?.rows_per_page,
          start: payload.data?.start,
        },
        {
          bearer_token: payload.user_token,
        }
      );

      showSuccessAndDispatch(dispatch, setPracticeStripePayments, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}


export function MakePaymentMethodDefault(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, false)

    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/make_payment_method_default`,
        {
          payment_method_id: payload.payment_method_id,
          stripe_customer_id: payload.stripe_customer_id,
        },
        {
          bearer_token: payload.user_token,
        }
      );

      showSuccessAndDispatch(dispatch, makePaymentMethodDefault, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}


export function DeletePaymentMethod(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, false)

    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/delete_payment_method`,
        {
          payment_method_id: payload.payment_method_id,
          stripe_customer_id: payload.stripe_customer_id,
        },
        {
          bearer_token: payload.user_token,
        }
      );

      showSuccessAndDispatch(dispatch, deletePaymentMethod, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}


export function AddPaymentMethod(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, true, false)

    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/add_payment_method`,
        {
          payment_method: payload.payment_method,
        },
        {
          bearer_token: payload.user_token,
        }
      );

      showSuccessAndDispatch(dispatch, addPaymentMethod, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}