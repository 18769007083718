import React from "react";
import AdminSettingsTable from "../../../components/tables/AdminSettingsTable";
import "./style.css";
import AddSetting from "./AddSetting";

const AdminSettings = () => {
  return (
    <div className="admin_settings">
      <h5 className="text-black">Add/Update Setting</h5>
      <hr></hr>
      <AddSetting></AddSetting>
      {/* <hr></hr> */}

      <br></br>
      <br></br>
      <h5 className="text-black">All Settings</h5>
      <AdminSettingsTable></AdminSettingsTable>
    </div>
  );
};

export default AdminSettings;
