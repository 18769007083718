import React from "react";

const Label = ({ label, isRequired = false, bold = false }) => {
  return (
    <label className="pass_label">
      {bold ? <strong>{label}</strong> : label}
      {isRequired && (
        <strong
          className="text-danger"
          style={{
            lineHeight: "14px",
            verticalAlign: "bottom",
            fontSize: "30px",
          }}
        >
          &nbsp; *
        </strong>
      )}
    </label>
  );
};

export default Label;
