/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./style.scss";
import {
  GetDefaultPaymentMethod,
  SetSubscriptionSliceBits,
} from "../../../../store/modules/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Result, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES } from "../../../../utilities/enumerations";
import StripeCard from "../StripeCard/StripeCard";
import { SetPaymentSliceBits } from "../../../../store/modules/paymentSlice";
import { UpdateSubscriptionLookupData } from "store/modules/accountSlice";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

const Test = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const navigate = useNavigate();
  const location = useLocation();
  const { is_purchase_subscription_success, subscription_lookup_data } =
    useSelector((state) => state.subscription);
  const { add_payment_method_success } = useSelector((state) => state.payment);
  const [isSubscriptionPurchaseSuccess, setIsSubscriptionPurchaseSuccess] =
    useState(false);
  const [paymentMethodAddedSuccess, setPaymentMethodAddedSuccess] =
    useState(false);

  // * Get default payment method
  useEffect(() => {
    dispatch(GetDefaultPaymentMethod({ user_token: account.user_token }));
  }, []);

  // * Check if sub purchase success
  useEffect(() => {
    if (is_purchase_subscription_success) {
      dispatch(
        SetSubscriptionSliceBits({
          bitToSet: "is_purchase_subscription_success",
          value: false,
        })
      );
      dispatch(UpdateSubscriptionLookupData({
        data: subscription_lookup_data
      }))
      setIsSubscriptionPurchaseSuccess(true);
    }
  }, [is_purchase_subscription_success]);

  // * Check if sub purchase success
  useEffect(() => {
    if (add_payment_method_success) {
      dispatch(
        SetPaymentSliceBits({
          bitToSet: "add_payment_method_success",
          value: false,
        })
      );
      setPaymentMethodAddedSuccess(true);
    }
  }, [add_payment_method_success]);

  const GetSubTitle = (start_date, endDate, is_free_trial) => {
    var c_str = `Your basic subscription period is from ${start_date && new Date(start_date).toDateString()
      } to ${endDate && new Date(endDate).toDateString()}.`;

    var str =
      parseInt(is_free_trial) === 1
        ? `Your first 3 months will be free trial period. When your trial ends you will be charged automatically every month.`
        : `. You will be charged automatically every month.`;

    return c_str + str;
  };

  return (
    <>
      {isSubscriptionPurchaseSuccess && subscription_lookup_data && (
        <Row className="stripe_subscription_main">
          <Col span={24}>
            <Row justify="center">
              <Col xs={22} sm={22} md={16} lg={12}>
                <Result
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                  status="success"
                  title={
                    "Congratulations on purchasing monthly basic subscription."
                  }
                  subTitle={GetSubTitle(
                    subscription_lookup_data.subscription_start_date,
                    subscription_lookup_data.subscription_end_date,
                    subscription_lookup_data.is_free_trial_active
                  )}
                  extra={[
                    <Button
                      onClick={() => {
                        navigate(
                          `/${ROLES[account.role]}/manage_subscriptions`
                        );
                      }}
                    >
                      Continue
                    </Button>,
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {paymentMethodAddedSuccess && (
        <Row className="stripe_subscription_main">
          <Col span={24}>
            <Row justify="center">
              <Col xs={22} sm={22} md={16} lg={12}>
                <Result
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                  status="success"
                  title="Congragulations!"
                  subTitle="Your payment method settings updated successfully"
                  extra={[
                    <Button
                      onClick={() => {
                        navigate("/practice/manage_subscriptions");
                      }}
                      key="buy"
                    >
                      Continue
                    </Button>,
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!isSubscriptionPurchaseSuccess && !paymentMethodAddedSuccess && (
        <Row className="stripe_subscription_main">
          <Row justify="center">
            <Col span={24} className="stripe_subscription_card">
              {!stripePromise ? (
                <div>
                  <p>Loading...</p>
                </div>
              ) : (
                <div>
                  <Elements stripe={stripePromise}>
                    <StripeCard
                      path={location.pathname.replace(/^\/+/, "")}
                    ></StripeCard>
                  </Elements>
                </div>
              )}
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
};

export default Test;
