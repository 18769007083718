import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAdminSettings,
  GetAllUsersQueries,
} from "../../store/modules/userSlice";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedTableHead from "./components/EnhancedTableHead";
import EnhancedTableFooter from "./components/EnhancedTableFooter";
import "./style.scss";

const EnhancedTable = () => {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const nameRef = useRef();
  const { adminSettings, adminSettingsCount } = useSelector(
    (state) => state.user
  );
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState({
    status: null,
    name: null,
    rows_per_page: 10,
    start: 0,
  });
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Id",
    },
    {
      id: "admin_name",
      numeric: true,
      disablePadding: false,
      label: "Admin Name",
    },
    {
      id: "key",
      numeric: true,
      disablePadding: false,
      label: "Key",
    },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "Value",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  // * Get results on page load
  useEffect(() => {
    dispatch(
      GetAdminSettings({
        user_token: account.user_token,
        data,
      })
    );
  }, []);

  // * Get results on filter change
  useEffect(() => {
    dispatch(
      GetAllUsersQueries({
        user_token: account.user_token,
        data,
      })
    );
  }, [data]);

  // * Handle filters change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  // * Handle pagiantion before click
  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };

  // * Handle pagiantion next click
  const handleNextClick = () => {
    if (adminSettingsCount > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  // * Handle delete settings
  const handleDeleteSetting = (id) => {
    alert("Needs Implementation");
  };

  return (
    <div className="">
      <hr></hr>
      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={headCells} />
              <TableBody>
                {adminSettings && adminSettings.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  adminSettings &&
                  adminSettings.length > 0 &&
                  adminSettings.map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.admin_name}</TableCell>
                        <TableCell>{item.key}</TableCell>
                        <TableCell>
                          {item.key == "platform_fee_percentage" ||
                          item.key == "stripe_fee_percentage"
                            ? item.value + " %"
                            : item.value}
                        </TableCell>
                        <TableCell align="">
                          <Tooltip title="Delete" placement="right">
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => handleDeleteSetting(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableFooter
            start={data.start}
            rows_per_page={data.rows_per_page}
            total_results_count={adminSettingsCount}
            handleRowCountChange={handleChange}
            handleBeforeClick={handleBeforeClick}
            handleNextClick={handleNextClick}
          ></EnhancedTableFooter>
        </Paper>
      </Box>
    </div>
  );
};

export default EnhancedTable;
