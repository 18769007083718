import React from "react";
import { Result } from "antd";
import "./style.scss";
import { NavLink } from "react-router-dom";

const EmailVerification = () => {
  return (
    <div className="verify_email">
      <div className="verify_email_card">
        <Result
          title="Verify your email!"
          subTitle={[
            <p className="mt-5">
              A verification link has been sent to your email address. Please
              check your inbox to verify your email.
            </p>,
            <p className="d-block">
              Please <NavLink to={"/login"}>Click here</NavLink> to login once
              you verified your email.
            </p>,
          ]}
        />
      </div>
    </div>
  );
};

export default EmailVerification;
