import React from "react";

const Input = ({
  label,
  inputValue,
  inputError,
  name,
  type,
  className,
  id,
  placeholder,
  bold,
  handleChange,
  locationRef,
  infoText,
  step,
  min,
  max,
  showInfoText,
}) => {
  return (
    <div className="form-group mt-2">
      {bold ? (
        <div className="input_label">
          <label htmlFor={id} className="form-label">
            <strong> {label}</strong>
          </label>
          {showInfoText && (
            <small className="text-muted mx-1" style={{ fontSize: "12px" }}>
              {infoText}
            </small>
          )}
        </div>
      ) : (
        <div className="input_label">
          <label htmlFor={id} className="form-label">
            {label}
          </label>
          {showInfoText && (
            <small className="text-muted" style={{ fontSize: "12px" }}>
              {infoText}
            </small>
          )}
        </div>
      )}
      {type === "text" ? (
        <input
          type={type}
          className="form-control w-100"
          id={id}
          placeholder={placeholder}
          name={name}
          onChange={(event) => handleChange(event)}
          value={inputValue}
          ref={locationRef}
        ></input>
      ) : (
        <input
          type={type}
          className="form-control"
          id={id}
          step={step}
          min={min}
          max={max}
          placeholder={placeholder}
          name={name}
          onChange={(event) => handleChange(event)}
          value={inputValue}
        ></input>
      )}
      <p className="m-0">
        <small className="input_errors">{inputError}</small>
      </p>
    </div>
  );
};

export default Input;
