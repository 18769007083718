import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { visuallyHidden } from "@mui/utils";
import { useSelector, useDispatch } from "react-redux";
import { GetAllUsersQueries } from "../../store/modules/userSlice";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import CheckIcon from "@mui/icons-material/Check";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MarkResolvedUserQuery } from "../../store/modules/userSlice";
import Button from "@mui/material/Button";
import "./style.scss";

const styles = {
  header: {
    fontWeight: "bold !important",
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Id",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "subject",
    numeric: true,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "message",
    numeric: true,
    disablePadding: false,
    label: "Message",
  },
  {
    id: "posted_at",
    numeric: true,
    disablePadding: false,
    label: "Posted At",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip placement="top">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ fontSize: "14px", fontWeight: "bold" }}
                classes={styles.header}
              >
                {headCell.id === "createdAt" ? (
                  <Tooltip title="DD-MM-YYYY" placement="top">
                    <strong className={styles.header}>{headCell.label}</strong>
                  </Tooltip>
                ) : (
                  <strong className={styles.header}>{headCell.label}</strong>
                )}

                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const nameRef = useRef();
  const { allUserQueries, allUsersQueriesCount } = useSelector(
    (state) => state.user
  );

  const [data, setData] = useState({
    status: null,
    name: null,
    rows_per_page: 10,
    start: 0,
  });

  useEffect(() => {
    dispatch(
      GetAllUsersQueries({
        user_token: account.user_token,
        data,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      GetAllUsersQueries({
        user_token: account.user_token,
        data,
      })
    );
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleClearFilters = () => {
    setData({
      status: null,
      name: null,
      rows_per_page: 10,
      start: 0,
    });
    setPage(0);
    nameRef.current.value = "";
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };
  const handleNextClick = () => {
    if (allUsersQueriesCount > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  const handleNameSearch = () => {
    setData((prevState) => ({
      ...prevState,
      name: nameRef.current.value,
    }));
  };

  const handleMarkResolved = (id) => {
    dispatch(
      MarkResolvedUserQuery({
        user_token: account.user_token,
        queryId: id,
      })
    );
  };
  return (
    <div className="">
      {/* Filters */}
      <div className="row m-0">
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingLeft: "0px" }}
        >
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Status
            </label>
            <FormControl fullWidth>
              <Select
                name="status"
                value={data.status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={true}>RESOLVED</MenuItem>
                <MenuItem value={false}>PENDING</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingRight: "0px  " }}
        >
          <div className="form-group mt-2">
            <label className="form-label">Name</label>
            <div className="input-group mb-3 name_filter_group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter name"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                ref={nameRef}
              ></input>
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={handleNameSearch}
              >
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mt-2 d-flex">
          <Button
            onClick={handleClearFilters}
            type="button"
            sx={{
              marginTop: 2,
              width: 200,
              height: 38,
              backgroundColor: "#747e8c",
              ":hover": { backgroundColor: "#747e8c" },
            }}
            variant="contained"
            color="info"
          >
            Clear All Filters
          </Button>
        </div>
      </div>
      <hr></hr>

      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {allUserQueries && allUserQueries.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  allUserQueries &&
                  stableSort(allUserQueries, getComparator(order, orderBy))
                    .slice(
                      page * data.rows_per_page,
                      page * data.rows_per_page + data.rows_per_page
                    )
                    .map((item) => {
                      return (
                        <TableRow hover tabIndex={-1} key={item.id}>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.subject}</TableCell>
                          <Tooltip title={item.message} placement="top">
                            <TableCell>
                              {item.message != null
                                ? item.message.substring(0, 20) + "..."
                                : ""}
                            </TableCell>
                          </Tooltip>
                          <TableCell>
                            {new Date(item.created_at)
                              .toLocaleDateString()
                              .replaceAll("/", "-")}
                          </TableCell>
                          <TableCell align="">
                            {item.is_resolved == 0 ? (
                              <Tooltip title="Mark Resolved" placement="top">
                                <IconButton
                                  color="info"
                                  aria-label="delete"
                                  disabled={
                                    parseInt(item.is_resolved) === 1
                                      ? true
                                      : false
                                  }
                                  onClick={() => handleMarkResolved(item.id)}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                                color="info"
                                aria-label="delete"
                                disabled
                              >
                                <CheckIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="table_custom_pagination d-flex justify-content-end">
            <div className="d-flex justify-content-center align-content-center">
              <div className="rows_count">
                <p className="">Rows per page:</p>
                <FormControl size="small" variant="outlined">
                  {/* <InputLabel id="demo-select-small">Age</InputLabel> */}
                  <Select
                    name="rows_per_page"
                    value={data.rows_per_page}
                    id="demo-select-small"
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="results_count">
                <p>
                  {data.start + 1}-
                  {data.start + data.rows_per_page > allUsersQueriesCount
                    ? allUsersQueriesCount
                    : data.start + data.rows_per_page}
                  &nbsp;of&nbsp;{allUsersQueriesCount}
                </p>
              </div>
              <div className="navigation_buttons">
                <IconButton
                  disabled={data.start === 0 ? true : false}
                  onClick={handleBeforeClick}
                  aria-label="delete"
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  onClick={handleNextClick}
                  aria-label="delete"
                  disabled={
                    data.start + data.rows_per_page >= allUsersQueriesCount
                      ? true
                      : false
                  }
                >
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
}
