import React from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Label from "./Label/Label";
import { OutlinedInput } from "@mui/material";

const MuiPasswordInput = ({
  label,
  inputValue,
  inputError,
  name,
  type,
  id,
  placeholder,
  variant = "standard",
  handleChange,
  showpasswords,
  autocomplete,
  infoText,
  showInfoText,
  isRequired,
  bold,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Label label={label} isRequired={isRequired} bold={bold}></Label>
      <FormControl fullWidth={true} variant={variant}>
        {variant === "outlined" ? (
          <OutlinedInput
            error={inputError && inputError !== "" ? true : false}
            fullWidth={true}
            inputProps={{
              style: { height: "20px", autoComplete: "new-password" },
            }}
            autoComplete="new-password"
            value={inputValue}
            onChange={(event) => handleChange(event)}
            name={name}
            id={id}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        ) : (
          <Input
            error={inputError && inputError !== "" ? true : false}
            fullWidth={true}
            inputProps={{
              style: { height: "35px", autoComplete: "new-password" },
            }}
            autoComplete="new-password"
            value={inputValue}
            onChange={(event) => handleChange(event)}
            name={name}
            id={id}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </FormControl>
      <small className="text-danger" style={{ fontSize: "0.75rem" }}>
        {inputError}
      </small>
    </div>
  );
};

export default MuiPasswordInput;
