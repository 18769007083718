import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SetBackDrop } from "./backDropSlice";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { httpGet, httpPost } from "../../services/httpService";

const initialState = {
  a_is_processing: false,
  is_mark_complete_success: false,
  isBooked: false,
  is_availability_posted: false,
  is_booking_request_sent: false,
  isRequestStatusUpdated: false,
  message: "",
  code: "",
  availability: {},
  locum_booked_dates: [],
  locum_posted_dates: [],
  filtered_locums: [],
  filteredLocumsCount: 0,
  allAvailablilities: [],
  locum_confirmed_bookings: [],
  locum_completed_bookings: [],
  locum_cancelled_bookings: [],
  practice_confirmed_bookings: [],
  practice_requested_bookings: [],
  practice_cancelled_bookings: [],
  practice_completed_bookings: [],
  practice_booking_history: [],
  practice_booking_history_count: 0,
  locum_booking_history: [],
  locum_booking_history_count: 0,
  locum_booking_requests: [],
  is_profile_incomplete_error: false,
  is_not_member_error: false,
  iseBookingCancelledSuccessfull: false,
  practice_booked_dates: [],
  practice_requested_dates: [],
  review_lookup_data: [],
};

const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    ResetAvailabilitiesSliceState() {
      return initialState
    },
    allAvailablilities(state, { payload }) {
      return {
        ...state,
        allAvailablilities: payload.data,
      };
    },
    postAvaliability(state, { payload }) {
      return {
        ...state,
        is_availability_posted: true,
      };
    },
    getAvaliabilityById(state, { payload }) {
      return {
        ...state,
        availability: payload.data,
      };
    },
    getAllLocumBookings(state, { payload }) {
      switch (payload.data.type) {
        case "confirmed_bookings":
          state.locum_confirmed_bookings = payload.data.bookings;
          state.locum_cancelled_bookings = state.locum_completed_bookings = [];
          break;
        case "completed_bookings":
          state.locum_completed_bookings = payload.data.bookings;
          state.locum_cancelled_bookings = state.locum_confirmed_bookings = [];
          break;
        case "cancelled_bookings":
          state.locum_cancelled_bookings = payload.data.bookings;
          state.locum_confirmed_bookings = state.locum_completed_bookings = [];
          break;
        default:
          state.locum_confirmed_bookings =
            state.locum_cancelled_bookings =
            state.locum_completed_bookings =
            [];
          break;
      }
    },
    getPracticeBookings(state, { payload }) {
      switch (payload.data.type) {
        case "confirmed_bookings":
          state.practice_confirmed_bookings = payload.data.bookings;
          state.practice_requested_bookings =
            state.practice_cancelled_bookings =
            state.practice_completed_bookings =
            [];
          break;
        case "completed_bookings":
          state.practice_completed_bookings = payload.data.bookings;
          state.practice_requested_bookings =
            state.practice_cancelled_bookings =
            state.practice_confirmed_bookings =
            [];
          break;
        case "pending_bookings":
          state.practice_requested_bookings = payload.data.bookings;
          state.practice_confirmed_bookings =
            state.practice_cancelled_bookings =
            state.practice_completed_bookings =
            [];
          break;
        case "cancelled_bookings":
          state.practice_cancelled_bookings = payload.data.bookings;
          state.practice_requested_bookings =
            state.practice_confirmed_bookings =
            state.practice_completed_bookings =
            [];
          break;
        default:
          state.practice_confirmed_bookings =
            state.practice_requested_bookings =
            state.practice_cancelled_bookings =
            state.practice_completed_bookings =
            [];
          break;
      }
    },
    sendBookingRequest(state, { payload }) {
      return {
        ...state,
        isBooked: true,
        is_booking_request_sent: true,
      };
    },
    SetAvailabilitySliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
    getAllLocumPostedAndBookedDates(state, { payload }) {
      if (payload.data && payload.data.posted_dates)
        return {
          ...state,
          locum_booked_dates: payload.data.booked_dates,
          locum_posted_dates: payload.data.posted_dates,
        };
    },
    getAllLocumBookedDates(state, { payload }) {
      if (payload.data && payload.data.booked_dates)
        return {
          ...state,
          locum_booked_dates: payload.data.booked_dates,
        };
    },
    getPracticeRequestedAndBookedDates(state, { payload }) {
      if (
        payload.data &&
        (payload.data.booked_dates || payload.data.requested_dates)
      )
        return {
          ...state,
          practice_booked_dates: payload.data.booked_dates,
          practice_requested_dates: payload.data.requested_dates,
        };
    },
    getPracticeBookingHistory(state, { payload }) {
      return {
        ...state,
        practice_booking_history: payload.data.practice_booking_history,
        practice_booking_history_count:
          payload.data.practice_booking_history_count,
      };
    },
    getLocumBookingHistory(state, { payload }) {
      return {
        ...state,
        locum_booking_history: payload.data.locum_booking_history,
        locum_booking_history_count: payload.data.locum_booking_history_count,
      };
    },
    markTaskComplete(state, { payload }) {
      if (payload.data.user.role === "practice") {
        state.practice_confirmed_bookings =
          state.practice_confirmed_bookings.map((item) => {
            // eslint-disable-next-line
            if (item.booking_id == payload.data.booking.id) {
              item.booking_status = payload.data.booking.booking_status;
            }
            return item;
          });
      }
      if (payload.data.user.role === "locum") {
        state.locum_confirmed_bookings = state.locum_confirmed_bookings.map(
          (item) => {
            // eslint-disable-next-line
            if (item.booking_id == payload.data.booking.id) {
              item.booking_status = payload.data.booking.booking_status;
            }
            return item;
          }
        );
      }

      state.is_mark_complete_success = true;
      state.review_lookup_data = payload.data.review_lookup
        ? payload.data.review_lookup
        : null;
    },
    getFilteredLocums(state, { payload }) {
      return {
        ...state,
        filtered_locums: payload.data.data.filtered_locums,
        filteredLocumsCount: payload.data.filtered_locums_count,
      };
    },
    getLocumBookingRequestes(state, { payload }) {
      return {
        ...state,
        locum_booking_requests: payload.data.data.booking_requests,
      };
    },
    locumBookingResponse(state, { payload }) {
      return {
        ...state,
        isRequestStatusUpdated:
          payload.data.request_status !== "requested" ? true : false,
      };
    },
    setProfileError(state, { payload }) {
      if (
        payload.response &&
        payload.response.data &&
        payload.response.data.data &&
        payload.response.data.data.code === "profile_incomplete"
      ) {
        return {
          ...state,
          is_profile_incomplete_error: true,
        };
      }

      if (
        payload.response &&
        payload.response.data &&
        payload.response.data.data &&
        payload.response.data.data.code === "not_member"
      ) {
        return {
          ...state,
          is_not_member_error: true,
        };
      }
    },
    cancelBooking(state, { payload }) {
      return {
        ...state,
        iseBookingCancelledSuccessfull: true,
      };
    },
  },
});

export const {
  ResetAvailabilitiesSliceState,
  allAvailablilities,
  postAvaliability,
  getAvaliabilityById,
  getAllLocumBookings,
  getPracticeBookings,
  sendBookingRequest,
  SetAvailabilitySliceBits,
  getAllLocumPostedAndBookedDates,
  getAllLocumBookedDates,
  getPracticeBookingHistory,
  getLocumBookingHistory,
  markTaskComplete,
  getFilteredLocums,
  getLocumBookingRequestes,
  locumBookingResponse,
  setProfileError,
  cancelBooking,
  getPracticeRequestedAndBookedDates,
} = bookingSlice.actions;
export default bookingSlice.reducer;

//----------------------------------------------------------------
// Common action creators
const showSuccessAndDispatch = (
  dispatch,
  action = null,
  response,
  showToast = false
) => {
  showBackDropAndLoader(dispatch, false, false); // Hide the backdrop on success
  if (action) {
    dispatch(action(response.data));
  }
  if (showToast) {
    SHOW_SUCCESS_TOAST(response);
  }
};

const showErrorAndLog = (dispatch, error, action = null, showToast = true) => {
  showBackDropAndLoader(dispatch, false, false); // Hide the backdrop on success
  console.log(error);
  if (action) {
    dispatch(action(error));
  }
  if (showToast) {
    SHOW_ERROR_TOAST(error);
  }
};

const showBackDropAndLoader = (
  dispatch,
  setbackdrop = false,
  setLoader = false
) => {
  dispatch(SetBackDrop(setbackdrop));
  dispatch(SetAvailabilitySliceBits({ bitToSet: "a_is_processing", value: setLoader }));
};

export function SetAllAvailabilities(payload) {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/locum_tasks/${payload.locum_id}`,
        {},
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(allAvailablilities(response.data));
      })
      .catch((error) => {
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function PostAvaliability(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/task`,
        {
          shift_hours: payload.data.shift_hours,
          charge_rate: payload.data.charge_rate,
          available_dates: payload.data.avaliable_dates,
          available_time: payload.data.available_time,
          is_update: payload.data.is_update,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(postAvaliability(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(setProfileError(error));
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetAvaliabilityById(payload) {
  return async (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/task/${payload.taskId}`,
        {},
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(getAvaliabilityById(response.data));
      })
      .catch((error) => {
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetLocumBookings(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/locum_bookings?booking_type=${payload.type}`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getAllLocumBookings, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetPracticeBookings(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/practice_booked_tasks?booking_type=${payload.type}`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getPracticeBookings, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function SendBookingRequest(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/send_booking_request`,
        {
          locum_id: payload.locum_id,
          requested_dates: payload.selectedDates,
          total_amount: payload.totalAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(sendBookingRequest(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(setProfileError(error));
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetAllLocumPostedAndBookedDates(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/locum_posted_and_booked_dates`,
        {
          bearer_token: payload.token,
        }
      );
      showSuccessAndDispatch(
        dispatch,
        getAllLocumPostedAndBookedDates,
        response
      );
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetAllLocumBookedDates(payload) {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/locum_booked_dates/${payload.locum_id}`,
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(getAllLocumBookedDates(response.data));
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetPracticeRequestedAndBookedDates(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/practice_requested_and_booked_dates`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(
        dispatch,
        getPracticeRequestedAndBookedDates,
        response
      );
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetPracticeBookingHistory(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/practice_booking_history`,
        {
          status: payload.data?.status,
          name: payload.data?.name,
          rows_per_page: payload.data?.rows_per_page,
          start: payload.data?.start,
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getPracticeBookingHistory, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetLocumBookingHistory(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/locum_booking_history`,
        {
          status: payload.data?.status,
          name: payload.data?.name,
          rows_per_page: payload.data?.rows_per_page,
          start: payload.data?.start,
        },
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getLocumBookingHistory, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function MarkTaskComplete(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/mark_task_complete/${payload.bookingId}`,
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(SetBackDrop(false));
        dispatch(markTaskComplete(response.data));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetFilteredLocums(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/get_filtered_locums`,
        {
          ...payload,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(getFilteredLocums(response.data));
        dispatch(SetBackDrop(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetLocumBookingRequestes(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/locum_booking_requests`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getLocumBookingRequestes, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function LocumBookingResponse(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/booking_response/${payload.data.booking_id}`,
        {
          cancellation_reason: payload.data.cancellation_reason,
          status: payload.data.status,
          booking_id: payload.data.booking_id,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(locumBookingResponse(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function CancelBooking(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/cancel_booking`,
        {
          cancellation_reason: payload.data.cancellation_reason,
          booking_id: payload.data.booking_id,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(cancelBooking(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}
