import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLES } from "../../../utilities/enumerations";

const BookingAccepted = () => {
  const account = useSelector((state) => state.account);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${ROLES[account.role]}/bookings`);
  };

  return (
    <div className="booking_accepted">
      <Result
        icon={<SmileOutlined />}
        title="Congratulation!"
        subTitle="Your booking is confirmed. You will be able to see your bookings in your account under My Bookings (link)."
        extra={
          <Button
            style={{
              backgroundColor: "#4493c5",
              ":hover": { backgroundColor: "#4493c5" },
            }}
            type="primary"
            onClick={handleClick}
          >
            My Bookings
          </Button>
        }
      />
    </div>
  );
};

export default BookingAccepted;
