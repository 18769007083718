/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { GetPracticeBookingHistory } from "../../store/modules/bookingSlice";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Button from "@mui/material/Button";
import { CAPITALIZE, GET_TABLE_COLUMNS } from "../../utilities/helper";
import EnhancedTableHead from "./components/EnhancedTableHead";
import "./style.scss";
import EnhancedTableFooter from "./components/EnhancedTableFooter";
import { Popover } from "antd";

export default function EnhancedTable({ current_tab }) {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const nameRef = useRef();
  const { practice_booking_history, practice_booking_history_count } =
    useSelector((state) => state.bookings);
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    status: null,
    rows_per_page: 10,
    start: 0,
  });

  const columnLabels =
    "Id, Locum Name, Request Status, Booking Status, Booked At";

  // * Get results on filter change
  useEffect(() => {
    if (current_tab === "booking_history") {
      dispatch(
        GetPracticeBookingHistory({
          user_token: account.user_token,
          data,
          show_loader: false,
        })
      );
    }
  }, [data]);

  // * Handle filters change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  // * Handle Clear filters
  const handleClearFilters = () => {
    setData({
      status: null,
      practiceName: null,
      rows_per_page: 10,
      start: 0,
    });
    setPage(0);
    nameRef.current.value = "";
  };

  // * Handle pagiantion before click
  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };

  // * Handle pagiantion next click
  const handleNextClick = () => {
    if (practice_booking_history_count > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  return (
    <div className="">
      {/* Filters */}
      <div className="row m-0">
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12 "
          style={{ paddingLeft: "0px" }}
        >
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Availability Status
            </label>
            <FormControl fullWidth>
              <Select
                name="status"
                value={data.status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={"in_progress"}>In Progress</MenuItem>
                <MenuItem value={"completed"}>Completed</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mt-2 d-flex">
          <Button
            onClick={handleClearFilters}
            type="button"
            sx={{
              marginTop: 2,
              width: 200,
              height: 38,
              backgroundColor: "#747e8c",
              ":hover": { backgroundColor: "#747e8c" },
            }}
            variant="contained"
            color="info"
          >
            Clear All Filters
          </Button>
        </div>
      </div>
      <hr></hr>

      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={GET_TABLE_COLUMNS(columnLabels)} />
              <TableBody>
                {practice_booking_history &&
                practice_booking_history.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={columnLabels.split(",").length}
                    >
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  practice_booking_history &&
                  practice_booking_history.length > 0 &&
                  practice_booking_history.map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                          {item.user_name && CAPITALIZE(item.user_name)}
                        </TableCell>
                        <TableCell>
                          {item.request_status &&
                          item.request_status === "accepted" ? (
                            <span className="text-success">
                              {CAPITALIZE(item.request_status)}
                            </span>
                          ) : (
                            <span
                              className={
                                item.request_status === "rejected"
                                  ? " text-danger"
                                  : "text-warning"
                              }
                            >
                              {item.request_status === "rejected"
                                ? "Declined"
                                : CAPITALIZE(item.request_status)}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.booking_status &&
                          (item.booking_status === "in_progress" ||
                            item.booking_status === "completed") ? (
                            <span className="text-success">
                              {item.booking_status === "in_progress"
                                ? "Confirmed"
                                : CAPITALIZE(item.booking_status)}
                            </span>
                          ) : item.booking_status === "requested" ? (
                            <span className="text-warning">
                              {CAPITALIZE(item.booking_status)}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {CAPITALIZE(item.booking_status)}
                            </span>
                          )}
                        </TableCell>
                        {/* <TableCell>
                          <Popover
                            placement="top"
                            content={item.cancellation_reason}
                            trigger="hover"
                          >
                            <span className="text-danger">
                              {item.cancellation_reason
                                ? item.cancellation_reason.substring(0, 20) +
                                  "..."
                                : ""}
                            </span>
                          </Popover>
                        </TableCell> */}
                        <TableCell>
                          {new Date(item.created_at)
                            .toLocaleDateString()
                            .replaceAll("/", "-")}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableFooter
            start={data.start}
            rows_per_page={data.rows_per_page}
            total_results_count={practice_booking_history_count}
            handleRowCountChange={handleChange}
            handleBeforeClick={handleBeforeClick}
            handleNextClick={handleNextClick}
          ></EnhancedTableFooter>
        </Paper>
      </Box>
    </div>
  );
}
