import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

const EnhancedTableFooter = ({
  start,
  rows_per_page,
  total_results_count,
  handleRowCountChange,
  handleBeforeClick,
  handleNextClick,
}) => {
  return (
    <div className="table_custom_pagination d-flex justify-content-end">
      <div className="d-flex justify-content-center align-content-center">
        <div className="rows_count">
          <p className="">Rows per page:</p>
          <FormControl size="small" variant="outlined">
            <Select
              name="rows_per_page"
              value={rows_per_page}
              id="demo-select-small"
              displayEmpty
              onChange={(event) => handleRowCountChange(event)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="results_count">
          <p>
            {start + 1}-
            {start + rows_per_page > total_results_count
              ? total_results_count
              : start + rows_per_page}
            &nbsp;of&nbsp;{total_results_count}
          </p>
        </div>
        <div className="navigation_buttons">
          <IconButton
            disabled={start === 0 ? true : false}
            onClick={(event) => handleBeforeClick(event)}
            aria-label="delete"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={(event) => handleNextClick(event)}
            aria-label="delete"
            disabled={
              start + rows_per_page >= total_results_count ? true : false
            }
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default EnhancedTableFooter;
