import { toast } from "react-hot-toast";
import { ROLES } from "./enumerations";
import { httpPost } from "services/httpService";

export const CAPITALIZE = (value) => {
  if (value && value.includes("_")) {
    var arr = value.split("_");
    var str = "";
    arr.forEach((element) => {
      str += element.charAt(0).toUpperCase() + element.slice(1);
      str += " ";
    });
    return str.trim();
  } else if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return "";
  }
};

export const SAFE_AMOUNT = (value) => {
  if (value && value.includes("$")) {
    var arr = value.split("$");
    var val = 0;
    if (arr.length > 0) {
      arr.forEach((element) => {
        if (element && element !== "") {
          val = element;
        }
      });
    }
    return val;
  } else {
    return value;
  }
};

export const FIX_TIMEZONE_OFFSET = (date) => {
  if (!date) return "";
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
};

export const GET_TIME_OF_DAY = (day, time_ranges, is_json = true) => {
  time_ranges = is_json ? JSON.parse(time_ranges) : time_ranges;
  if (time_ranges && time_ranges[day].start && time_ranges[day].end) {
    var str = "(" + time_ranges[day].start + " - " + time_ranges[day].end + ")";

    if (str && str.includes(":00")) {
      str.replaceAll(":00", "");
    }
    return str
  } else {
    return "(Not specified)";
  }
};

export const GET_ERROR_MESSAGE = (value) => {
  // 'success' | 'info' | 'warning' | 'error'
  var err_msg = "Server Error";
  var err_type = "error";
  if (value.response.data.code === 404) {
    err_msg = value.response.data.message;
    err_type = "warning";
  }
  if (value.response.data.code === 403) {
    err_msg = value.response.data.message;
    err_type = "warning";
  }
  var snackbar_obj = {
    snackbarOpen: true,
    snackbarMessage: err_msg,
    snackbarType: err_type,
    snackbarAutoHide: 5000,
  };
  return snackbar_obj;
};

export const NOTIFICATION_PARAMS = (role, type) => {
  var path = "bookings";
  var tab = null;

  if (!role || !type) {
    return {
      path: path,
      tab: tab,
    };
  }
  var tab_type = type.split("booking_")[1];
  switch (tab_type) {
    case "declined":
      path = "bookings";
      tab = "cancelled_bookings";
      break;
    case "accepted":
      path = "bookings";
      tab = "confirmed_bookings";
      break;
    case "completed":
      path = "bookings";
      tab = "completed_bookings";
      break;
    case "cancelled":
      path = "bookings";
      tab = "cancelled_bookings";
      break;
    case "requests_sent":
    case "pending":
      if (role === ROLES.practice) {
        path = "bookings";
        tab = "pending_bookings";
      }
      break;
    case "requests_received":
      if (role === ROLES.locum) {
        path = "review_booking_requests";
      }
      break;
    default:
      break;
  }

  return {
    path: path,
    tab: tab,
  };
};

export const GET_SUCCESS_MESSAGE = (value) => {
  // 'success' | 'info' | 'warning' | 'error'
  var msg = "Success";
  var type = "success";
  if (value.data.code === 200) {
    msg = value.data.message;
    type = "success";
  }
  var snackbar_obj = {
    snackbarOpen: true,
    snackbarMessage: msg,
    snackbarType: type,
    snackbarAutoHide: 4000,
  };
  return snackbar_obj;
};

export const SHOW_SUCCESS_TOAST = (value) => {
  //* Handling success messages
  var message = "Success";
  if (value && value.data && value.data.code === 200) {
    message = value.data.message;
  }
  return toast.success(message, {
    style: {
      border: "1px solid #34eb40",
      padding: "10px",
    },
  });
};

export const SHOW_ERROR_TOAST = (value) => {
  //* Handling error messgages
  //* Handle all errors with code 500 :Server Error
  //* Handle all validation errors 409 :Validation Errors
  //* Handle all authorization errors 401 :Validation Errors

  var message = "Error";
  if (value.response.status === 500) {
    message = value.response.statusText;
    if (
      value.response &&
      value.response.data &&
      value.response.data.data &&
      value.response.data.data.message
    ) {
      message = value.response.data.data.message;
    }
    if (
      value.response &&
      value.response.data &&
      value.response.data.data &&
      !value.response.data.data.message
    ) {
      message = value.response.data.data;
    }
  } else if (
    value &&
    value.response &&
    value.response.data &&
    !value.response.data.data
  ) {
    message = value.response.data.message;
  } else if (
    value &&
    value.response &&
    value.response.data &&
    value.response.data.code === 409
  ) {
    if (
      value.response.data.data &&
      Object.keys(value.response.data.data).length > 0
    ) {
      Object.keys(value.response.data.data).forEach((key) => {
        message = value.response.data.data[key][0];
      });
    } else {
      message = value.response.data.message;
    }
  }

  return toast.error(message, {
    style: {
      border: "1px solid #e61e38",
      padding: "10px",
    },
  });
};

export const GET_SITE_CONTENT = (data, section, key) => {
  var str = "";
  if (data && data.length > 0) {
    let arr = data.filter(
      (item) => item.key === key && item.section === section
    );
    arr && arr.length > 0
      ? (str = arr[0].value)
      : (str = `section:${section}, key:${key} => Error:string not found.`);
  } else {
    str = `section:${section},key:${key} => Error:string not found.`;
  }
  return str;
};

export const GET_IMAGE = (name, is_profile = false) => {
  if (is_profile) {
    return process.env.REACT_APP_API_RESOURCES_URI + name;
  } else {
    return process.env.REACT_APP_API_RESOURCES_URI + name;
  }
};

export const GET_TABLE_COLUMNS = (columnLabels) => {
  // Split the comma-separated column labels into an array
  const labelsArray = columnLabels.split(",");

  // Create an array of objects with desired properties
  const columnObjects = labelsArray.map((label) => {
    return {
      id: label.trim().replace(/\s+/g, "_").toLowerCase(),
      numeric: true, // You can set this to your desired value
      disablePadding: false, // You can set this to your desired value
      label: label.trim(),
    };
  });
  return columnObjects;
};

export const NOTIFICATION_PERMISSIONS = () => {
  if (window.Notification && window.Notification !== "granted") {
    console.log("%c Requesting notification permission.", "color: orange;");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("%c Notification permission granted.", "color: green;");
      } else {
        console.log("%c Notification permission denied.", "color: orange;");
      }
    });
  } else {
    console.log("%c Notification permission granted.", "color: green;");
  }
};


export const LOG_ERRORS = async (error, payload) => {
  try {
    const response = await httpPost(
      '/api/log_react_app_errors', { error: error.stack }
    );
    console.log(response)
  } catch (error) {
    console.log(error)
  }
};
