import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Calculator = () => {
  const [values, setValues] = React.useState({
    numberformat: "",
  });
  const [calulatedValue, setCalulatedValue] = useState("");
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (values.numberformat !== "") {
      setCalulatedValue(
        values.numberformat - (((2.9 + 8) / 100) * values.numberformat + 0.3)
      );
    }
  }, [values.numberformat]);

  return (
    <div className="rates_calculator">
      <h5>Calculator</h5>
      <hr></hr>
      <div className="alert alert-primary" role="alert">
        <FontAwesomeIcon className="" icon="fa-solid fa-circle-info" />
        &nbsp; Stripe fee is 2.9% + $0.30 of total amount per transaction.
      </div>

      <TextField
        fullWidth={true}
        value={values.numberformat}
        onChange={handleChange}
        name="numberformat"
        placeholder="Enter Amount"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
        variant="outlined"
      />
      {values.numberformat && (
        <div className="mt-4">
          <p className="m-0 p-0">
            You will get : ${calulatedValue} against ${values.numberformat}
          </p>
        </div>
      )}
    </div>
  );
};

export default Calculator;
