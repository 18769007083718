import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SetBackDrop } from "./backDropSlice";
import { SetReviewModal } from "./modalSlice";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { httpGet } from "../../services/httpService";

const initialState = {
  u_is_processing: false,
  isContactUsFormSubmitted: false,
  isAdminAddedSuccessfully: false,
  isSettingSavedSuccessfully: false,
  allUsers: [],
  allUsersCount: 0,
  allUserQueries: [],
  allUsersQueriesCount: 0,
  userProfileInfo: {},
  userReviews: [],
  allUsersReviews: [],
  allUsersReviewsCount: 0,
  adminSettings: [],
  adminSettingsCount: 0,
  locum_posted_dates: [],
  locum_available_dates: [],
  booked_dates: [],
  locum_info: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ResetUserSliceState(state, { payload }) {
      return initialState;
    },
    getAllUsers(state, { payload }) {
      return {
        ...state,
        allUsers: payload.data.users,
        allUsersCount: payload.data.users_count,
      };
    },
    deleteUser(state, { payload }) {
      return {
        ...state,
        allUsers: state.allUsers.filter((user) => user.id !== payload.data.id),
      };
    },
    contactUs(state, { payload }) {
      return {
        ...state,
        isContactUsFormSubmitted: true,
      };
    },
    SetUserSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
    getAllUsersQueries(state, { payload }) {
      return {
        ...state,
        allUserQueries: payload.data.queries,
        allUsersQueriesCount: payload.data.queries_count,
      };
    },
    addAdmin(state, { payload }) {
      return {
        ...state,
        isAdminAddedSuccessfully: true,
      };
    },
    getUserProfileInfo(state, { payload }) {
      return {
        ...state,
        userProfileInfo: payload.data.user_details,
      };
    },
    markResolveQuery(state, { payload }) {
      state.allUserQueries = state.allUserQueries.map((item) => {
        // eslint-disable-next-line
        if (item.id == payload.data.id) {
          item.is_resolved = payload.data.is_resolved;
        }
        return item;
      });
    },
    getUserReviews(state, { payload }) {
      return {
        ...state,
        userReviews: payload.data,
      };
    },
    getallUsersReviews(state, { payload }) {
      return {
        ...state,
        allUsersReviews: payload.data.users_reviews,
      };
    },
    getAdminSettings(state, { payload }) {
      return {
        ...state,
        adminSettings: payload.data.admin_settings,
        adminSettingsCount: payload.data.admin_settings_count,
      };
    },
    getLocumPostedDates(state, { payload }) {
      return {
        ...state,
        locum_posted_dates: payload.data.locum_posted_dates
          ? payload.data.locum_posted_dates
          : [],
      };
    },
    getLocumAvailableDates(state, { payload }) {
      return {
        ...state,
        locum_available_dates:
          payload.data.locum_available_dates[0].available_dates,
        locum_info: payload.data.locum_info[0],
      };
    },
    setFilteredLocumInfo(state, { payload }) {
      return {
        ...state,
        locum_info: payload.data.locum_info[0],
        locum_available_dates: payload.data.locum_available_dates,
        booked_dates: payload.data.booked_dates,
      };
    },
  },
});

export const {
  ResetUserSliceState,
  getAllUsers,
  deleteUser,
  contactUs,
  SetUserSliceBits,
  getAllUsersQueries,
  addAdmin,
  getUserProfileInfo,
  markResolveQuery,
  getUserReviews,
  getallUsersReviews,
  getAdminSettings,
  getLocumPostedDates,
  setFilteredLocumInfo,
} = userSlice.actions;
export default userSlice.reducer;

// Common action creators
const showSuccessAndDispatch = (
  dispatch,
  action = null,
  response,
  showToast = false
) => {
  showBackDropAndLoader(dispatch, false, false); // Hide the backdrop on success
  if (action) {
    dispatch(action(response.data));
  }
  if (showToast) {
    SHOW_SUCCESS_TOAST(response);
  }
};

const showErrorAndLog = (dispatch, error, action = null, showToast = true) => {
  showBackDropAndLoader(dispatch, false, false); // Hide the backdrop on success
  console.log(error);
  if (action) {
    dispatch(action(error));
  }
  if (showToast) {
    SHOW_ERROR_TOAST(error);
  }
};

const showBackDropAndLoader = (
  dispatch,
  setbackdrop = false,
  setLoader = false
) => {
  dispatch(SetBackDrop(setbackdrop));
  dispatch(
    SetUserSliceBits({
      bitToSet: "u_is_processing",
      value: setLoader,
    })
  );
};

export function GetAllUsers(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/get_all_users`,
        {
          is_deleted: payload.data.is_deleted,
          role: payload.data.role,
          rows_per_page: payload.data.rows_per_page,
          start: payload.data.start,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(getAllUsers(response.data));
      })
      .catch((error) => {
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function DeleteUser(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .delete(
        `${process.env.REACT_APP_API_URI}/api/delete_user/${payload.userId}`,
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(deleteUser(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function SubmitReview(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/review_user`,
        {
          review_data: payload.reviewData,
          review_info: payload.reviewInfo,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          SetReviewModal({
            bitToSet: payload.isResponseModal
              ? "practiceReviewModalOpen"
              : "locum_review_modal_open",
            bit_value: false,
            reviewModalParam: "reviewData",
            reviewModalData: {},
          })
        );
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function ContactUs(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/contact_us`,
        {
          name: payload.data.user_name,
          email: payload.data.email,
          subject: payload.data.subject,
          message: payload.data.message,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(contactUs(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetAllUsersQueries(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/get_all_queries`,
        {
          status: payload.data.status,
          name: payload.data.name,
          rows_per_page: payload.data.rows_per_page,
          start: payload.data.start,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(getAllUsersQueries(response.data));
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function AddAdmin(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/add_admin`,
        {
          email: payload.data.email,
          user_name: payload.data.user_name,
          password: payload.data.password,
          c_password: payload.data.c_password,
          role: "admin",
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(addAdmin(response.data));
        dispatch(SetBackDrop(false));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(SetBackDrop(false));
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetUserProfileInfo(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/user_profile/${payload.userId}`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getUserProfileInfo, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function MarkResolvedUserQuery(payload) {
  return async (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/mark_resolve_query/${payload.queryId}`,
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(markResolveQuery(response.data));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function LocumReviewLookup(payload) {
  return async (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/locum_review_lookup/${payload.bookingId}`,
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          dispatch(
            SetReviewModal({
              bitToSet: "locum_review_modal_open",
              bit_value:
                response.data.data.has_practice_reviewed === 0 ? true : false,
              reviewModalParam: "reviewData",
              reviewModalData: response.data.data,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function UserReviewLookup(payload) {
  return async (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/user_review_lookup`, {
        headers: {
          Authorization: `Bearer ${payload.user_token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data) {
          if (
            response.data.data.data.review_lookup_data &&
            response.data.data.data.review_lookup_data.reviewer_id
          ) {
            dispatch(
              SetReviewModal({
                bitToSet: "practiceReviewModalOpen",
                bit_value: true,
                reviewModalParam: "reviewData",
                reviewModalData: response.data.data.data.review_lookup_data,
              })
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function GetUserReviews(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false);
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_API_URI}/api/user_reviews`,
        {
          bearer_token: payload.user_token,
        }
      );
      showSuccessAndDispatch(dispatch, getUserReviews, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetAllUsersReviews(payload) {
  return async (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/get_users_reviews`, {
        headers: {
          Authorization: `Bearer ${payload.user_token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch(getallUsersReviews(response.data));
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function EditUserReview(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/edit_user_review`,
        {
          review_id: payload.data.review_id,
          feedback: payload.data.feedback,
          rating: payload.data.rating,
          wwa: payload.data.wwa,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetAdminSettings(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/get_admin_settings`,
        {
          status: payload.data.status,
          name: payload.data.name,
          rows_per_page: payload.data.rows_per_page,
          start: payload.data.start,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(getAdminSettings(response.data));
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function AddAdminSetting(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/add_admin_setting`,
        {
          key: payload.data.key,
          value: payload.data.value,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetLocumPostedDates(payload) {
  return async (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/get_locum_posted_dates`, {
        headers: {
          Authorization: `Bearer ${payload.user_token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch(getLocumPostedDates(response.data));
      })
      .catch((error) => {
        console.log(error);
        // SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetFilteredLocumInfoAndDates(payload) {
  return async (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/get_locum_available_dates/${payload.locum_id}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(setFilteredLocumInfo(response.data));
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}
