import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "@iconify/react";
import {
  MarkTaskComplete,
  SetAvailabilitySliceBits,
} from "../../store/modules/bookingSlice";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import Timezone from "dayjs/plugin/timezone";
import Utc from "dayjs/plugin/utc";
import { CAPITALIZE, GET_TIME_OF_DAY } from "../../utilities/helper";
import { DAYS_ABBR, ROLES } from "../../utilities/enumerations";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.scss";
import { Card, Col, Divider, Row } from "antd";
import AntDesignButton from "../others/Button/AntDesignButton";
import { CreateChatChannel } from "../../store/modules/chatSlice";
import { SetReviewModal } from "../../store/modules/modalSlice";
import { confirmAlert } from "react-confirm-alert";

dayjs.extend(RelativeTime);
dayjs.extend(Timezone);
dayjs.extend(Utc);

const Category = ({
  booking_id,
  id,
  created_at,
  practice_id,
  location,
  lat,
  lng,
  distance_range,
  practice_name,
  practice_full_name,
  requestedDates,
  practiceTimeRange,
  practice_email,
  payment_status,
  total_amount,
  completed_at,
  show_actions = false,
  booking_status,
  request_status,
  add_calender_btn = false,
  show_cancellation_reason = false,
  cancellation_reason
}) => {
  const account = useSelector((state) => state.account);
  const bookings = useSelector((state) => state.bookings);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // * Check if mark complete is successfull
  useEffect(() => {
    if (bookings.is_mark_complete_success) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_mark_complete_success", value: false }));
      if (bookings.review_lookup_data) {
        dispatch(
          SetReviewModal({
            bitToSet: "locum_review_modal_open",
            bit_value: true,
            reviewModalParam: "reviewData",
            reviewModalData: bookings.review_lookup_data,
          })
        );
      }
    }
  }, [bookings.is_mark_complete_success, bookings.review_lookup_data, dispatch]);

  // * Handle message noe
  const handleMessageNow = (id) => {
    dispatch(
      CreateChatChannel({
        userId: id,
        user_token: account.user_token,
      })
    );
  };

  //* Decline booking
  const handleClickCancelBooking = (booking_id) => {
    navigate(
      `/${account.role}/cancel_booking/${booking_id}`,
      {
        state: {
          booking_id: booking_id,
        },
      },
      true
    );
  };

  // * Handle mark complete
  const handleMarkComplete = (id) => {
    confirmAlert({
      message: "Are you sure you want to mark the booking as completed?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              MarkTaskComplete({
                bookingId: id,
                user_token: account.user_token,
              })
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card className="card category_card" hoverable>
          <div className="card-body p-3">
            <div className="d-flex justify-content-between">
              <p className="m-0 ">
                {request_status === "rejected" ? (
                  <small>
                    Request Status: &nbsp;
                    <span className="text-danger">Declined</span>
                  </small>
                ) : (
                  <small>
                    Booking Status:
                    {booking_status === "requested" ? (
                      <span className="text-primary">
                        {/* &nbsp; {CAPITALIZE(booking_status)} */}
                        &nbsp;&nbsp; Pending
                      </span>
                    ) : booking_status === "cancelled" ? (
                      <small>
                        &nbsp;
                        <span className="text-danger">Cancelled</span>
                      </small>
                    ) : (
                      <span className="text-success">
                        &nbsp;
                        {booking_status === "in_progress"
                          ? "Confirmed"
                          : CAPITALIZE(booking_status)}
                      </span>
                    )}
                  </small>
                )}
              </p>
              <p className="m-0" style={{ textTransform: "capitalize" }}>
                {dayjs(created_at + "Z")
                  .tz(dayjs.tz.guess())
                  .fromNow(true)}
                &nbsp;ago
              </p>
            </div>
            <Divider></Divider>
            <div className="row">
              {practice_name && practice_name !== "" && (
                <>
                  <div className="col-9 icon-with-detail">
                    <div className="">
                      <Icon
                        icon="material-symbols:list-alt-rounded"
                        color="#5bab67"
                        width="22"
                        height="22"
                      />
                    </div>
                    <div className="">
                      <NavLink
                        to={`/${account.role}/user_profile/${practice_id}`}
                        state={{
                          user_id: practice_id,
                          active_tab: "detailed_info",
                        }}
                      >
                        {CAPITALIZE(practice_name)}
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </div>
            {add_calender_btn && (
              <div className="row">
                <div className="col-9 icon-with-detail">
                  <div className="">
                    <Icon icon="zondicons:date-add" width="20" height="20" />
                  </div>
                  <div className="">
                    <AntDesignButton
                      handleClick={() => {
                        alert("Will be available soon.");
                      }}
                      btn_height="27"
                      size="small"
                      btn_label="Add to calender"
                    ></AntDesignButton>
                  </div>
                </div>
              </div>
            )}
            <div className="col-9 icon-with-detail">
              <div className="">
                <Icon
                  icon="ic:round-message"
                  color="#5ae8a0"
                  width="22"
                  height="22"
                />
              </div>
              <div className="">
                <NavLink
                  onClick={() => handleMessageNow(practice_id)}
                  to={`/${ROLES[account.role]}/chat`}
                  state={{ user_id: practice_id }}
                >
                  Send a message
                </NavLink>
              </div>
            </div>

            <Row>
              <Col
                span={24}
                className="icon-with-detail"
                style={{ minHeight: "180px", alignItems: "flex-start" }}
              >
                <div>
                  <Icon
                    icon="uis:calender"
                    color="#5e8ae0"
                    width="22"
                    height="22"
                  />
                </div>
                <div className="row requested_dates">
                  {requestedDates &&
                    JSON.parse(requestedDates).map((ele, index) => {
                      return (
                        <small key={index}>
                          {index +
                            1 +
                            ": " +
                            dayjs(ele).format("dddd") +
                            " " +
                            dayjs(ele).format("D-MMMM-YYYY") +
                            " " +
                            "" +
                            GET_TIME_OF_DAY(
                              DAYS_ABBR[dayjs(ele).get("day")],
                              practiceTimeRange
                            )}
                        </small>
                      );
                    })}
                </div>
              </Col>
            </Row>

            {show_actions && booking_status === "in_progress" && (
              <Row>
                <Divider></Divider>
                <Col span={24}>
                  <Row gutter={[10, 0]}>
                    {booking_status !== "completed" && (
                      <Col span={12}>
                        {" "}
                        <AntDesignButton
                          margin_y={[0, 10]}
                          block={true}
                          btn_label="Mark Complete"
                          handleClick={() => handleMarkComplete(booking_id)}
                        />
                      </Col>
                    )}
                    <Col span={12}>
                      <AntDesignButton
                        danger={true}
                        block={true}
                        btn_label="Cancel booking"
                        handleClick={() => handleClickCancelBooking(booking_id)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {show_cancellation_reason &&
              <Row>
                <Divider></Divider>
                <Col span={24}>
                  <p className="text-danger">Reason : {cancellation_reason}</p>
                </Col>
              </Row>
            }
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Category;
