import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ValidateAddAdmin } from "../../../utilities/validator";
import { ROLES } from "../../../utilities/enumerations";
import { AddAdmin as AddAdminSubmit } from "../../../store/modules/userSlice";

const AddAdmin = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const location = useLocation();
  const { id, role } = location.state;
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCPassword, setShowCPassword] = React.useState(false);
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({
    email: "",
    user_name: "",
    password: "",
    c_password: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateAddAdmin(data));
    setIsSubmitting(true);
  };

  const submitForm = () => {
    try {
      dispatch(
        AddAdminSubmit({
          data: data,
          user_token: account.user_token,
        })
      );
      // setRedirect(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="add_admin">
      <h5>Add Admin</h5>
      <hr></hr>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <label className="form-label">Username</label>
              <TextField
                error={formError.user_name && formError.user_name !== ""}
                id="outlined-error"
                fullWidth={true}
                name="user_name"
                value={data.user_name}
                onChange={handleChange}
                helperText={
                  formError.user_name != null || formError.user_name !== ""
                    ? formError.user_name
                    : ""
                }
              />
            </div>
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <label className="form-label">Email</label>
              <TextField
                error={formError.email && formError.email !== ""}
                id="outlined-error"
                fullWidth={true}
                onChange={handleChange}
                name="email"
                value={data.email}
                helperText={
                  formError.email != null || formError.email !== ""
                    ? formError.email
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group mt-4">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <label className="form-label">Password</label>
              <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
                <OutlinedInput
                  error={formError.password && formError.password !== ""}
                  value={data.password}
                  name="password"
                  autoComplete="new-password"
                  notched={false}
                  fullWidth={true}
                  onChange={handleChange}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <small className="text-danger" style={{ fontSize: "0.75rem" }}>
                {formError.password}
              </small>
            </div>
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <label className="form-label">Confirm Password</label>
              <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
                <OutlinedInput
                  error={formError.c_password && formError.c_password !== ""}
                  value={data.c_password}
                  autoComplete="new-password"
                  name="c_password"
                  notched={false}
                  fullWidth={true}
                  onChange={handleChange}
                  id="outlined-adornment-c_password"
                  type={showCPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showCPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <small className="text-danger" style={{ fontSize: "0.75rem" }}>
                {formError.c_password}
              </small>
            </div>
          </div>
        </div>
        <div className="form-group">
          <Button
            color="success"
            type="submit"
            sx={{ width: 200, height: 50, marginTop: 5 }}
            variant="contained"
            startIcon={<SendIcon />}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddAdmin;
