import React, { useEffect } from "react";
import "./termsconditions.css";

const TermsConditions = () => {
  useEffect(() => {
    document.title = "Locumy Only | Terms & conditions";
  }, []);
  return (
    <div className="container" id="termsconditions">
      <div className="m-auto termsconditions">
        <div className="row">
          <div className="col-sm-12 col-lg-5 m-auto">
            <div className="terms">
              <h4>Terms and Conditions</h4>
              <p>
                Welcome to Website Name! These terms and conditions outline the
                rules and regulations for the use of Company Name's Website,
                located at Website.com. By accessing this website we assume you
                accept these terms and conditions. Do not continue to use
                Website Name if you do not agree to take all of the terms and
                conditions stated on this page. The following terminology
                applies to these Terms and Conditions, Privacy Statement and
                Disclaimer Notice and all Agreements: “Client”, “You” and “Your”
                refers to you, the person log on this website and compliant to
                the Company's terms and conditions. “The Company”, “Ourselves”,
                “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”,
                or “Us”, refers to both the Client and ourselves. All terms
                refer to the offer, acceptance and consideration of payment
                necessary to undertake the process of our assistance to the
                Client in the most appropriate manner for the express purpose of
                meeting the Client's needs in respect of provision of the
                Company's stated services, in accordance with and subject to,
                prevailing law of Netherlands. Any use of the above terminology
                or other words in the singular, plural, capitalization and/or
                he/she or they, are taken as interchangeable and therefore as
                referring to same.
              </p>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-sm-12 col-lg-6">
            <div className="privacy">
              <h4>Privacy Policy</h4>
              <p>
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly. We do not ensure that
                the information on this website is correct, we do not warrant
                its completeness or accuracy; nor do we promise to ensure that
                the website remains available or that the material on the
                website is kept up to date.
              </p>
              <ul>
                <li>lorem ipsum dolor sit amet, consectetur adip</li>
                <li>lorem ipsum dolor sit amet, consectetur adip</li>
                <li>lorem ipsum dolor sit amet, consectetur adip</li>
                <li>lorem ipsum dolor sit amet, consectetur adip</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
