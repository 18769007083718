import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active_step: 0,
  selected_dates_save: [],
  selected_dates_show: [],
  locum_booked_dates: [],
  total_amount: 0,
  locum_id: null,
  date: null,
  from_date: null,
  to_date: null,
  sort_by: null
};

const browseStepsSlice = createSlice({
  name: "browseSetps",
  initialState,
  reducers: {
    ResetBrowseStepsSlice(state, { payload }) {
      return initialState;
    },
    SetSteps(state, { payload }) {
      return {
        ...state,
        active_step: payload,
      };
    },
    SetDatesSave(state, { payload }) {
      return {
        ...state,
        selected_dates_save: payload,
      };
    },
    SetDatesShow(state, { payload }) {
      return {
        ...state,
        selected_dates_show: payload,
      };
    },
    SetTotalAmount(state, { payload }) {
      return {
        ...state,
        selected_dates_show: payload,
      };
    },
    SetLocumId(state, { payload }) {
      return {
        ...state,
        locum_id: payload,
      };
    },
    ResetBrowseSteps(state, { payload }) {
      return {
        active_step: 0,
        selected_dates_save: [],
        selected_dates_show: [],
        locum_booked_dates: [],
        total_amount: 0,
        locum_id: null,
        date: null,
        from_date: null,
        to_date: null,
      };
    },
    SetBrowseSliceSate(state, { payload }) {
      payload &&
        payload.length > 0 &&
        payload.forEach((element) => {
          state[element.bitToSet] = element.value;
        });
    },
  },
});

export const {
  ResetBrowseStepsSlice,
  SetSteps,
  SetDatesSave,
  SetDatesShow,
  SetTotalAmount,
  SetLocumId,
  ResetBrowseSteps,
  SetBrowseSliceSate,
} = browseStepsSlice.actions;
export default browseStepsSlice.reducer;
