import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Label from "./Label/Label";
import './style.scss'

const PhoneInput = ({
  label,
  inputValue,
  inputError,
  handlePhoneChange,
  placeholder,
  name,
  bold,
  isRequired,
  is_custom_styled = true,
}) => {
  return (
    <div>
      <Label label={label} isRequired={isRequired} bold={bold}></Label>
      <div className={is_custom_styled ? "custom_tel_input_wrapper" : ""}>
        <ReactPhoneInput
          enableSearch={false}
          countryCodeEditable={false}
          onlyCountries={["au"]}
          name={name}
          value={inputValue ? inputValue : "61"}
          placeholder={placeholder ? placeholder : "+61 (00) 0000 0000"}
          onChange={(event) => handlePhoneChange(event)}
          specialLabel=""
        />
      </div>
      <small className="input_errors">{inputError}</small>
    </div>
  );
};

export default PhoneInput;
