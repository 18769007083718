/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { SetBackDrop } from "./backDropSlice";
import { httpPost } from "services/httpService";

const initialState = {
  is_channel_created: false,
  is_loading: false,
  channel_id: ""
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    ResetChatSliceState(state, { payload }) {
      return {
        is_channel_created: false,
        channel_id: ""
      }
    },
    setUserChannel(state, { payload }) {
      return {
        ...state,
        is_channel_created: true,
        channel_id: payload.data[0]?.channel?.id
      };
    },
    SetChatSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
  },
});

export const { setUserChannel, ResetChatSliceState, SetChatSliceBits } = chatSlice.actions;
export default chatSlice.reducer;

//----------------------------------------------------------------

const showSuccessAndDispatch = (dispatch, action = null, response, showToast = false) => {
  showBackDropAndLoader(dispatch, false, false) // Hide the backdrop on success
  if (action) {
    dispatch(action(response.data));
  }
  if (showToast) {
    SHOW_SUCCESS_TOAST(response);
  }
};

const showErrorAndLog = (dispatch, error, action = null, showToast = true) => {
  showBackDropAndLoader(dispatch, false, false) // Hide the backdrop on success
  console.log(error);
  if (action) {
    dispatch(action(error));
  }
  if (showToast) {
    SHOW_ERROR_TOAST(error);
  }
};

const showBackDropAndLoader = (dispatch, setbackdrop = false, setLoader = false) => {
  dispatch(SetBackDrop(setbackdrop));
  dispatch(SetChatSliceBits({
    bitToSet: "is_loading",
    value: setLoader,
  }))
};

export function CreateChatChannel(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, true)
    try {
      const response = await httpPost(
        '/api/create_stream_channel',
        {
          user_id: payload.userId,
        },
        {
          bearer_token: payload.user_token, // Pass the bearer token in the config
        }
      );
      showSuccessAndDispatch(dispatch, setUserChannel, response, false);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}
