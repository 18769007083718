/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllStripePayments,
  GetStripeBalanceInfo,
} from "../../store/modules/paymentSlice";
import PaymentInfoTable from "../tables/PaymentInfoTable";
import AllPaymentsTable from "../tables/AllPaymentsTable";
import "./style.scss";
import { Col, Row, Tabs } from "antd";
import { useState } from "react";
import IsProcessing from "../others/IsProcessing/IsProcessing";
import AllSubscriptionsTable from "../tables/AllSubscriptionsTable";
import { GetAllStripeSubscriptions } from "../../store/modules/subscriptionSlice";

const AllPayments = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const payment = useSelector((state) => state.payment);
  const [CurrentTab, setCurrentTab] = useState(null);

  const { p_is_processing } = useSelector((state) => state.payment);
  const { sc_is_processing } = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(
      GetStripeBalanceInfo({
        user_token: account.user_token,
      })
    );
    dispatch(
      GetAllStripeSubscriptions({
        user_token: account.user_token,
        show_loader: true,
      })
    );
  }, []);

  // This `useEffect` is triggered when the value of `CurrentTab` changes.
  useEffect(() => {
    // Check if `CurrentTab` exists and is not an empty string.
    if (CurrentTab && CurrentTab !== "") {
      // Based on the value of `CurrentTab`, perform different actions.
      switch (CurrentTab) {
        case "all_subscriptions":
          dispatch(
            GetAllStripeSubscriptions({
              user_token: account.user_token,
              show_loader: true,
            })
          );
          break;
        case "all_payments":
          dispatch(
            GetAllStripePayments({
              user_token: account.user_token,
              show_loader: true,
            })
          );
          break;
        default:
          // Handle other cases or tabs, if necessary.
          break;
      }
    }
  }, [CurrentTab]);

  return (
    <div className="allPayments">
      <div className="acc_info">
        <h5>
          Balances
          <small className="text-muted">
            ({payment.stripe_balance_data.livemode ? "Live Mode" : "Test Mode"})
          </small>
        </h5>
        <hr></hr>
        <PaymentInfoTable payment={payment}></PaymentInfoTable>
      </div>
      <br></br>
      <br></br>

      <Tabs
        onChange={(val) => {
          setCurrentTab(val);
        }}
        type="card"
        items={[
          {
            label: `All Subscriptions`,
            key: "all_subscriptions",
            children: (
              <Row>
                {sc_is_processing ? (
                  <IsProcessing />
                ) : (
                  <Col span={24}>
                    <AllSubscriptionsTable current_tab={CurrentTab} />
                  </Col>
                )}
              </Row>
            ),
          },
          {
            label: `All Payments`,
            key: "all_payments",
            children: (
              <Row>
                {p_is_processing ? (
                  <IsProcessing />
                ) : (
                  <Col span={24}>
                    <AllPaymentsTable current_tab={CurrentTab} />
                  </Col>
                )}
              </Row>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AllPayments;
