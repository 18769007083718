import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserProfileInfo } from "../../store/modules/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Col, Tabs, Row } from "antd";
import dayjs from "dayjs";
import { CAPITALIZE, GET_TIME_OF_DAY } from "../../utilities/helper";
import { DAYS_ABBR, ROLES, WEEK_DAYS } from "../../utilities/enumerations";
import UserReview from "../../components/cards/UserReview";
import { CreateChatChannel } from "../../store/modules/chatSlice";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import AntDesignButton from "../../components/others/Button/AntDesignButton";
import CompletedBookingCard from "../../components/cards/CompletedBookingCard";
import IsProcessing from "../../components/others/IsProcessing/IsProcessing";
import "./style.scss";

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { userProfileInfo, u_is_processing } = useSelector(
    (state) => state.user
  );
  const location = useLocation();
  useEffect(() => {
    const url = window.location.href;
    const url_arr = url.split("/");
    dispatch(
      GetUserProfileInfo({
        user_token: account.user_token,
        userId: location.state
          ? location.state.user_id
          : url_arr[url_arr.length - 1],
        show_loader: true,
      })
    );
  }, [account.user_token, dispatch, location.state]);

  const handleMessageNow = (user_id) => {
    dispatch(
      CreateChatChannel({
        userId: user_id,
        user_token: account.user_token,
      })
    );
    navigate(`/${ROLES[account.role]}/chat`, { state: { user_id: user_id } });
  };

  if (u_is_processing) {
    return <IsProcessing></IsProcessing>;
  }

  return (
    <div className="user_profile">
      <AntDesignButton
        icon_name="icon-park-outline:back"
        tooltip_title="Go Back"
        placement="bottom"
        handleClick={() => {
          navigate(-1);
        }}
      />

      <div className="user_profile_img">
        <div className="img_container">
          {userProfileInfo.basic_info &&
            userProfileInfo.basic_info.profileImageName ? (
            <img
              alt="profile.png"
              src={
                process.env.REACT_APP_API_PROFILE_IMAGES_URI +
                userProfileInfo.basic_info.profileImageName
              }
            ></img>
          ) : (
            <img alt="profile.png" src={process.env.REACT_APP_API_RESOURCES_URI + "profile.png"}></img>
          )}
        </div>
      </div>
      <div className="info_container">
        {userProfileInfo.basic_info &&
          userProfileInfo.basic_info.id &&
          userProfileInfo.basic_info.id != account.userId && (
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              sx={{
                height: 45,
                backgroundColor: "#4493c5",
                ":hover": { backgroundColor: "#4493c5" },
              }}
              onClick={() =>
                handleMessageNow(userProfileInfo.basic_info.id)
              }
            >
              Message Now
            </Button>
          )}
      </div>
      <br></br>
      <br></br>
      <div>
        <Tabs
          size="large"
          defaultActiveKey={
            location.state?.active_tab
              ? location.state.active_tab
              : "detailed_info"
          }
          type="card"
        >
          {/* Basic Info */}
          <Tabs.TabPane
            tab="Basic Info"
            tabKey="basic_info"
            key="basic_info"
          >
            <div className=" user_basic_info p-0 m-auto">
              <h5>
                {userProfileInfo.basic_info &&
                  CAPITALIZE(userProfileInfo.basic_info.role)}
              </h5>
              <hr></hr>
              <p>
                <strong>User Id : </strong>
                {userProfileInfo.basic_info &&
                  userProfileInfo.basic_info.id}
              </p>
              <p>
                <strong>User Name : </strong>
                {userProfileInfo.basic_info &&
                  userProfileInfo.basic_info.user_name}
              </p>
              <p>
                <strong>Full Name :</strong>
                {userProfileInfo.basic_info &&
                  userProfileInfo.basic_info.full_name}
              </p>
              <p>
                <strong>Is Email Verified : </strong>
                {userProfileInfo.basic_info &&
                  userProfileInfo.basic_info.email_verified_at
                  ? " Yes"
                  : " No"}
              </p>
              <p>
                <strong>Registered On : </strong>
                {userProfileInfo.basic_info &&
                  dayjs().calendar(
                    dayjs(userProfileInfo.basic_info.created_at)
                  )}
              </p>
            </div>
          </Tabs.TabPane>
          {/* Detailed_info */}
          <Tabs.TabPane
            tab="Detailed Info"
            tabKey="detailed_info"
            key="detailed_info"
          >
            <div className=" user_basic_info p-0 m-auto">
              <h5>
                {userProfileInfo.basic_info &&
                  CAPITALIZE(userProfileInfo.basic_info.role)}
              </h5>
              <hr></hr>
              {userProfileInfo.basic_info &&
                userProfileInfo.basic_info.role === ROLES.admin && (
                  <>
                    <p>Not Available</p>
                  </>
                )}
              {/* Practice Detailed info */}
              {userProfileInfo.basic_info &&
                userProfileInfo.basic_info.role === ROLES.practice && (
                  <>
                    <p>
                      <strong>Sector : </strong>
                      {userProfileInfo.detailed_info &&
                        CAPITALIZE(userProfileInfo.detailed_info.sector)}
                    </p>
                    <p>
                      <strong>Appointment Time : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.appointment_time}
                    </p>
                    <p>
                      <strong>Unpaid Break : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.unpaid_break}
                    </p>
                    {/* <p>
                          <strong>Available Time : </strong>
                          {userProfileInfo.detailed_info &&
                          userProfileInfo.detailed_info.available_time ===
                            "flexible"
                            ? CAPITALIZE(
                                userProfileInfo.detailed_info.available_time
                              )
                            : TIME_OF_DAY[
                                userProfileInfo.detailed_info.available_time
                              ]}
                        </p> */}
                    <p>
                      <strong>Practice Name : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.practice_name}
                    </p>
                    <p>
                      <strong>Practice Address : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.practice_address}
                    </p>
                    <p>
                      <strong>Vaccination Status : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.vaccination_status}
                    </p>
                    <p>
                      <strong>Pretest : </strong>
                      {userProfileInfo.detailed_info &&
                        CAPITALIZE(userProfileInfo.detailed_info.pretest)}
                    </p>
                    <p>
                      <strong>Softwares : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.softwares.length >
                        0 &&
                        userProfileInfo.detailed_info.softwares.map(
                          (item, index, arr) =>
                            arr[arr.length - 1] === item
                              ? CAPITALIZE(item)
                              : CAPITALIZE(item) + ", "
                        )}
                    </p>
                    <p>
                      <strong>Equipments : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.equipments.length >
                        0 &&
                        userProfileInfo.detailed_info.equipments.map(
                          (item, index, arr) =>
                            arr[arr.length - 1] === item
                              ? CAPITALIZE(item)
                              : CAPITALIZE(item) + ", "
                        )}
                    </p>
                    <p>
                      <strong>Profile : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.profile}
                    </p>
                  </>
                )}

              {/* Locum Detailed info */}
              {userProfileInfo.basic_info &&
                userProfileInfo.basic_info.role === ROLES.locum && (
                  <>
                    <p>
                      <strong>Sector : </strong>
                      {userProfileInfo.detailed_info &&
                        CAPITALIZE(userProfileInfo.detailed_info.sector)}
                    </p>
                    {/* <p>
                          <strong>Excluded Company : </strong>
                          {userProfileInfo.detailed_info &&
                            userProfileInfo.detailed_info.excluded_companies
                              .length > 0 &&
                            userProfileInfo.detailed_info.excluded_companies.map(
                              (item, index, arr) =>
                                arr[arr.length - 1] === item
                                  ? CAPITALIZE(item)
                                  : CAPITALIZE(item) + ", "
                            )}
                        </p> */}
                    <p>
                      <strong>Softwares : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.softwares.length >
                        0 &&
                        userProfileInfo.detailed_info.softwares.map(
                          (item, index, arr) =>
                            arr[arr.length - 1] === item
                              ? CAPITALIZE(item)
                              : CAPITALIZE(item) + ", "
                        )}
                    </p>
                    <p>
                      <strong>Companies Worked With : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.prev_companies
                          .length > 0 &&
                        userProfileInfo.detailed_info.prev_companies.map(
                          (item, index, arr) =>
                            arr[arr.length - 1] === item
                              ? CAPITALIZE(item)
                              : CAPITALIZE(item) + ", "
                        )}
                    </p>
                    <p>
                      <strong>Languages : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.language_1 &&
                        userProfileInfo.detailed_info.language_1 + ", "}
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.language_2 &&
                        userProfileInfo.detailed_info.language_2 + ", "}
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.language_3 &&
                        userProfileInfo.detailed_info.language_3}
                    </p>
                    <p>
                      <strong>Profile : </strong>
                      {userProfileInfo.detailed_info &&
                        userProfileInfo.detailed_info.profile}
                    </p>
                    <p className="d-flex flex-row">
                      <strong>Time Available : </strong>
                      <div>
                        {userProfileInfo.detailed_info &&
                          userProfileInfo.detailed_info.time_range &&
                          Object.keys(DAYS_ABBR).map((item, index) => {
                            return (
                              <span className="d-block" key={index}>
                                &nbsp;
                                {WEEK_DAYS[item]}&nbsp;
                                {GET_TIME_OF_DAY(
                                  DAYS_ABBR[item],
                                  userProfileInfo.detailed_info.time_range,
                                  false
                                )}
                              </span>
                            );
                          })}
                      </div>
                    </p>
                  </>
                )}
            </div>
          </Tabs.TabPane>
          {/* Completed bookings */}
          <Tabs.TabPane
            tab="Completed bookings"
            tabKey="achievements"
            key="achievements"
          >
            <div className=" user_basic_info p-0 m-auto">
              <h5>
                {userProfileInfo.basic_info &&
                  CAPITALIZE(userProfileInfo.basic_info.role)}
              </h5>
              <hr></hr>
              {/* Admin */}
              {userProfileInfo &&
                userProfileInfo.basic_info &&
                userProfileInfo.basic_info.role === ROLES.admin && (
                  <>
                    {userProfileInfo.basic_info && <p>Not Available</p>}
                  </>
                )}
              {/* Locum */}
              {userProfileInfo && userProfileInfo.basic_info && userProfileInfo.basic_info.role === ROLES.locum && (
                <>
                  {userProfileInfo.basic_info && <p><strong>Total bookings completed : </strong> {userProfileInfo && userProfileInfo.locum_bookings_completed && userProfileInfo.locum_bookings_completed}</p>}
                  {userProfileInfo.completed_bookings && (
                    <Row gutter={[10, 10]}>
                      {userProfileInfo.completed_bookings.length > 0 ? (userProfileInfo.completed_bookings.map((ele, index) => {
                        return (
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={8}
                            xxl={8}
                          >
                            <CompletedBookingCard
                              booking_id={ele.booking_id}
                              key={index}
                              booking_status={ele.booking_status}
                              id={ele.task_id}
                              total_amount={ele.total_amount}
                              created_at={ele.created_at}
                              user_id={ele.practice_id}
                              user_name={ele.practice_name}
                              user_full_name={
                                ele.practice_full_name
                              }
                              practice_email={ele.practice_email}
                              sector={ele.sector}
                              lat={ele.lat}
                              lng={ele.lng}
                              request_status={ele.request_status}
                              requestedDates={ele.requested_dates}
                              practiceTimeRange={ele.time_range}
                            ></CompletedBookingCard>
                          </Col>
                        );
                      })) : <p className="">No bookings available</p>}
                    </Row>
                  )}
                </>
              )}

              {/* Practice */}
              {userProfileInfo && userProfileInfo.basic_info && userProfileInfo.basic_info.role === ROLES.practice && userProfileInfo.completed_bookings && (
                <Row gutter={[10, 10]}>
                  {userProfileInfo.completed_bookings.length > 0 ? (userProfileInfo.completed_bookings.map((ele, index) => {
                    return (
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={10}
                        xl={8}
                        xxl={8}
                      >
                        <CompletedBookingCard
                          booking_id={ele.booking_id}
                          key={index}
                          booking_status={ele.booking_status}
                          id={ele.task_id}
                          total_amount={ele.total_amount}
                          created_at={ele.created_at}
                          user_id={ele.practice_id}
                          user_name={ele.locum_name}
                          user_full_name={
                            ele.practice_full_name
                          }
                          practice_email={ele.practice_email}
                          sector={ele.sector}
                          lat={ele.lat}
                          lng={ele.lng}
                          request_status={ele.request_status}
                          requestedDates={ele.requested_dates}
                          practiceTimeRange={ele.time_range}
                        ></CompletedBookingCard>
                      </Col>
                    );
                  })) : <p className="">No bookings available</p>}
                </Row>
              )}
            </div>
          </Tabs.TabPane>
          {/* User reviews */}
          <Tabs.TabPane tab="Reviews" tabKey="reviews" key="reviews">
            <div className=" user_basic_info p-0 m-auto">
              <h5>
                {userProfileInfo.basic_info &&
                  CAPITALIZE(userProfileInfo.basic_info.role)}
              </h5>
              <hr></hr>
              {userProfileInfo.basic_info && userProfileInfo.basic_info.role === ROLES.admin && <p>Not Available</p>}
              {userProfileInfo.basic_info && userProfileInfo.basic_info.role === ROLES.locum && (
                <Row gutter={[10, 10]}>
                  {userProfileInfo.user_reviews &&
                    userProfileInfo.user_reviews.length > 0 ? (
                    userProfileInfo.user_reviews.map((ele, index) => {
                      return (
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={10}
                          xl={6}
                          xxl={4}
                        >
                          <UserReview
                            key={index}
                            rating={ele.rating}
                            feedback={ele.feedback}
                            reviewerImageUrl={ele.profileImageName}
                            created_at={ele.created_at}
                            reviewerName={
                              ele.full_name
                                ? ele.full_name
                                : ele.user_name
                            }
                          ></UserReview>
                        </Col>
                      );
                    })
                  ) : <p className="">No reviews available</p>}
                </Row>
              )}
              {userProfileInfo.basic_info && userProfileInfo.basic_info.role === ROLES.practice && (
                <Row gutter={[10, 10]}>
                  {userProfileInfo.user_reviews &&
                    userProfileInfo.user_reviews.length > 0 ? (
                    userProfileInfo.user_reviews.map((ele, index) => {
                      return (
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={10}
                          xl={6}
                          xxl={4}
                        >
                          <UserReview
                            key={index}
                            rating={ele.rating}
                            feedback={ele.feedback}
                            reviewerImageUrl={ele.profileImageName}
                            created_at={ele.created_at}
                            reviewerName={
                              ele.full_name
                                ? ele.full_name
                                : ele.user_name
                            }
                          ></UserReview>
                        </Col>
                      );
                    })
                  ) : <p className="">No reviews available</p>}
                </Row>
              )}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default UserProfile;
