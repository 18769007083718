import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Factory from "../../assets/images/factory.jpg";
const Tasker = (props) => {
  return (
    <div className="container">
      <div className="row testimonial">
        <div className="col-lg-4 p-0 m-0">
          <img src={Factory} alt=""></img>
        </div>
        <div className="col-md-10 col-lg-8 p-3">
          <div className="row">
            <h4>{props.name}</h4>
            <p>
              <strong>Specialities: assembly, pet care, gardening</strong>
            </p>
            <p>
              Returning to the workforce as a single mum, Sam had to find
              something that could be flexible and cover the cost of childcare.
            </p>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 row_with_border">
              <p>
                <strong>WHAT THE REVIEWS SAY</strong>
              </p>
              <p>Very nice and caring in trying circumstances! Thanks again</p>
              <br></br>
              <p>
                <strong>TRUST</strong>
              </p>
              <p>4.9 stars from 185 reviews</p>
            </div>
            <div className="col-lg-6">
              <p>
                <strong>BADGES</strong>
              </p>
              <p>
                <FontAwesomeIcon icon="fa-regular fa-id-card" />
                &nbsp;
                <strong>Digital Id</strong>
              </p>
              <p>
                <FontAwesomeIcon icon="fa-regular fa-credit-card" />
                &nbsp;
                <strong>Police Check</strong>
              </p>
              <p>
                <FontAwesomeIcon icon="fa-solid fa-otter" /> &nbsp;
                <strong>International Seller</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasker;
