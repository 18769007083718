/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidateUpdateprofileAdmin } from "utilities/validator";
import {
  UpdateProfile,
  UploadProfilePicture,
} from "store/modules/accountSlice";
import PhoneInput from "components/others/PhoneInput";
import MuiInput from "components/others/MuiInput";
import Profile from "assets/images/profile.png";
import ImageUpload from "../ImageUpload";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import IsProcessing from "components/others/IsProcessing/IsProcessing";
import "./style.scss";

const AdminProfileEdit = ({ profileName, user_id = null }) => {
  const dispatch = useDispatch();
  const { profile_data, user_token, profileImageName, a_is_processing } =
    useSelector((state) => state.account);
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({
    user_name: "",
    email: "",
    full_name: "",
    prefix: "",
    mobile_number: "",
  });

  const [profileImg, setProfileImg] = useState({
    imageBase64: "",
    imageName: "",
    imageType: "",
  });

  useEffect(() => {
    if (user_id) {
      setData({
        ...data,
        user_id: user_id,
      });
    }
  }, []);

  useEffect(() => {
    if (profile_data && profile_data.basic_info) {
      setData({
        ...data,
        ...profile_data.basic_info,
      });
    }
  }, [profile_data]);

  useEffect(() => {
    if (profileImg.imageBase64 !== null && profileImg.imageBase64 !== "") {
      dispatch(
        UploadProfilePicture({
          user_token: user_token,
          imageData: profileImg,
        })
      );
    }
  }, [profileImg.imageBase64]);

  const handlePhoneChange = (e) => {
    if (data.mobile_number !== "") {
      setFormError({ ...formError, mobile_number: "" });
    }
    setData((preval) => {
      return {
        ...preval,
        mobile_number: e,
      };
    });
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm(true);
    }
  }, [formError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateUpdateprofileAdmin(data));
    setIsSubmitting(true);
  };

  const submitForm = () => {
    try {
      dispatch(UpdateProfile({ ...data, user_token: user_token }));
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };

  const imageUplaod = (imageList) => {
    setProfileImg({
      imageBase64: imageList[0].data_url,
      imageName: imageList[0].file.name,
      imageType: imageList[0].file.type,
    });
  };

  return (
    <div>
      <div className="admin_acc_details">
        <div className="form-group user_profile_img">
          <div className="img_container">
            {profileImageName !== null ? (
              <img
                alt="profile.png"
                src={
                  process.env.REACT_APP_API_PROFILE_IMAGES_URI +
                  profileImageName
                }
              ></img>
            ) : (
              <img alt="profile.png" src={Profile}></img>
            )}
          </div>
          <ImageUpload imageUplaod={imageUplaod}></ImageUpload>
        </div>
        {!a_is_processing ? (
          <div className="row">
            <div className="col-lg-12 m-auto">
              <hr></hr>
              <form onSubmit={handleSubmit}>
                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        label="Full Name"
                        bold={false}
                        type="text"
                        className="form-control"
                        id="user_name"
                        name="full_name"
                        inputError={formError.full_name}
                        inputValue={data.full_name}
                        handleChange={handleChange}
                        placeholder="Enter a unique username"
                        disabled={true}
                      ></MuiInput>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        isRequired={true}
                        label="Username"
                        bold={false}
                        type="text"
                        className="form-control"
                        id="user_name"
                        name="user_name"
                        inputError={formError.user_name}
                        inputValue={data.user_name}
                        handleChange={handleChange}
                        disabled={true}
                        placeholder="Enter a unique username"
                      ></MuiInput>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        isRequired={true}
                        label="Email"
                        bold={false}
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        inputError={formError.email}
                        inputValue={data.email}
                        handleChange={handleChange}
                        placeholder="Enter a unique username"
                      ></MuiInput>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <PhoneInput
                        isRequired={true}
                        label={"Mobile Number"}
                        name="mobile_number"
                        inputError={formError.mobile_number}
                        inputValue={data.mobile_number}
                        handlePhoneChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4 mb-2">
                  <Button
                    type="submit"
                    sx={{
                      width: 200,
                      height: 45,
                      backgroundColor: "#4493c5",
                      ":hover": { backgroundColor: "#4493c5" },
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveAltIcon />}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <IsProcessing></IsProcessing>
        )}
      </div>
    </div>
  );
};

export default AdminProfileEdit;
