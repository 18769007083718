import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { SetAvailabilitySliceBits } from "../../store/modules/bookingSlice";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import Timezone from "dayjs/plugin/timezone";
import Utc from "dayjs/plugin/utc";
import { CAPITALIZE, GET_TIME_OF_DAY } from "../../utilities/helper";
import { DAYS_ABBR, ROLES } from "../../utilities/enumerations";
import { Card, Col, Divider, Row } from "antd";
import AntDesignButton from "../others/Button/AntDesignButton";
import { SetReviewModal } from "../../store/modules/modalSlice";
import "./style.scss";

dayjs.extend(RelativeTime);
dayjs.extend(Timezone);
dayjs.extend(Utc);

const CompletedBookingCard = ({
  booking_id,
  created_at,
  user_id,
  location,
  lat,
  lng,
  distance_range,
  user_name,
  user_full_name,
  requestedDates,
  practiceTimeRange,
  practice_email,
  payment_status,
  total_amount,
  completed_at,
  show_actions = false,
  booking_status,
  request_status,
  add_calender_btn = false,
}) => {
  const account = useSelector((state) => state.account);
  const bookings = useSelector((state) => state.bookings);
  const dispatch = useDispatch();

  // * Check if mark complete is successfull
  useEffect(() => {
    if (bookings.is_mark_complete_success) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_mark_complete_success", value: false }));
      if (bookings.review_lookup_data) {
        dispatch(
          SetReviewModal({
            bitToSet: "locum_review_modal_open",
            bit_value: true,
            reviewModalParam: "reviewData",
            reviewModalData: bookings.review_lookup_data,
          })
        );
      }
    }
  }, [bookings.is_mark_complete_success, bookings.review_lookup_data, dispatch]);

  return (
    <Row>
      <Col span={24}>
        <Card className="card category_card" hoverable>
          <div className="card-body p-3">
            <div className="d-flex justify-content-between">
              <p className="m-0 ">
                {request_status === "rejected" ? (
                  <small>
                    Request Status: &nbsp;
                    <span className="text-danger">Declined</span>
                  </small>
                ) : (
                  <small>
                    Booking Status:
                    {booking_status === "requested" ? (
                      <span className="text-primary">
                        {/* &nbsp; {CAPITALIZE(booking_status)} */}
                        &nbsp;&nbsp; Pending
                      </span>
                    ) : booking_status === "cancelled" ? (
                      <small>
                        &nbsp;
                        <span className="text-danger">Cancelled</span>
                      </small>
                    ) : (
                      <span className="text-success">
                        &nbsp;
                        {booking_status === "in_progress"
                          ? "Confirmed"
                          : CAPITALIZE(booking_status)}
                      </span>
                    )}
                  </small>
                )}
              </p>
              <p className="m-0" style={{ textTransform: "capitalize" }}>
                {dayjs(created_at + "Z")
                  .tz(dayjs.tz.guess())
                  .fromNow(true)}
                &nbsp;ago
              </p>
            </div>
            <Divider></Divider>
            <div className="row">
              {user_name && user_name !== "" && (
                <>
                  <div className="col-9 icon-with-detail">
                    <div className="">
                      <Icon
                        icon="material-symbols:list-alt-rounded"
                        color="#5bab67"
                        width="22"
                        height="22"
                      />
                    </div>
                    <div className="">
                      <span>{CAPITALIZE(user_name)}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            {add_calender_btn && (
              <div className="row">
                <div className="col-9 icon-with-detail">
                  <div className="">
                    <Icon icon="zondicons:date-add" width="20" height="20" />
                  </div>
                  <div className="">
                    <AntDesignButton
                      handleClick={() => {
                        alert("Will be available soon.");
                      }}
                      btn_height="27"
                      size="small"
                      btn_label="Add to calender"
                    ></AntDesignButton>
                  </div>
                </div>
              </div>
            )}
            {account.role && account.role !== ROLES.practice &&
              <Row>
                <Col
                  span={24}
                  className="icon-with-detail"
                  style={{ minHeight: "180px", alignItems: "flex-start" }}
                >
                  <div>
                    <Icon
                      icon="uis:calender"
                      color="#5e8ae0"
                      width="22"
                      height="22"
                    />
                  </div>
                  <div className="row requested_dates">
                    {requestedDates &&
                      JSON.parse(requestedDates).map((ele, index) => {
                        return (
                          <>
                            <small key={index}>
                              {index +
                                1 +
                                ": " +
                                dayjs(ele).format("dddd") +
                                " " +
                                dayjs(ele).format("D-MMMM-YYYY") +
                                " " +
                                "" +
                                GET_TIME_OF_DAY(
                                  DAYS_ABBR[dayjs(ele).get("day")],
                                  practiceTimeRange
                                )}
                            </small>
                          </>
                        );
                      })}
                  </div>
                </Col>
              </Row>}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default CompletedBookingCard;
