import React from "react";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const EnhancedTableHead = ({ headCells }) => {
  const styles = {
    header: {
      fontWeight: "bold !important",
    },
  };

  return (
    <TableHead>
      <TableRow>
        {headCells &&
          headCells.length > 0 &&
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.id === "createdAt" ? (
                <Tooltip title="DD-MM-YYYY" placement="top">
                  <strong className={styles.header}>{headCell.label}</strong>
                </Tooltip>
              ) : (
                <strong className={styles.header}>{headCell.label}</strong>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
