import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer"; // Import your combined reducers
import storage from "redux-persist/lib/storage"; // Import Redux Persist storage
import { persistReducer, persistStore } from "redux-persist"; // Import Redux Persist functions
import thunk from "redux-thunk"; // Import Redux Thunk middleware

// Configure the Redux Persist settings
const persistConfig = { 
    key: "application_data", // Key for your persisted data in storage
    storage, // Storage engine for Redux Persist (in this case, local storage)
    blacklist: [ // List of slices to exclude from persistence
        "backdrop",
        "bookings",
        "alert",
        "user",
        "modal",
        "payment",
        "chat",
        "settings",
        "browseSteps",
        "subscription",
    ],
};

// Create a persisted reducer with the specified configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure and create the Redux store
const store = configureStore({
    reducer: persistedReducer, // Use the persisted reducer
    // devTools: process.env.REACT_APP_BUILD === "development" ? true : false, // Enable Redux DevTools if specified
    devTools: true,
    middleware: [thunk], // Apply Redux Thunk middleware for handling async actions
});


// Create and export the Redux Persist store persistor
const persistor = persistStore(store);
// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
export { store, persistor };

