import React from "react";

const Blog = (props) => {
  return (
    <div className="col-lg-4 m-auto mb-2 mt-2">
      <div className="card">
        <img src={props.Factory} className="card-img-top" alt="..."></img>
        <div className="card-body">
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p>
          <a href="#" className="text-decoration-none">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
