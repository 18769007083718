/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { ROLES } from "../../utilities/enumerations";
import AdminProfileEdit from "./Admin/AdminProfileEdit";
import PracticeAccount from "./Practice/PracticeAccount";
import LocumAccount from "./Locum/LocumAccount";

const Profile = () => {
  const account = useSelector((state) => state.account);


  return (
    <>
      {account.role === ROLES.admin &&
        account.profile_data &&
        account.profile_data.basic_info &&
        account.profile_data &&
        account.profile_data.basic_info && <AdminProfileEdit />}
      {account.role === ROLES.locum && <LocumAccount />}
      {account.role === ROLES.practice && <PracticeAccount />}
    </>
  );
};

export default Profile;
