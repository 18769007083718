import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const account = useSelector((state) => state.account);
  const location = useLocation();

  return account.user_token && account.user_token !== "" ? (
    <Outlet />
  ) : (
    <Navigate to="login" state={{ from: location }} replace></Navigate>
  );
};

export default ProtectedRoutes;
