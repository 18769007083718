import React from "react";
import AllUsersTable from "../../../components/tables/AllUsersTables";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AddAdmin from "./AddAdmin";

const Users = () => {
  const account = useSelector((state) => state.account);
  const navigate = useNavigate();
  const handleAddAdmin = () => {
    navigate(
      "/admin/add_admin",
      {
        state: {
          id: account.userId,
          role: account.role,
        },
      },
      true
    );
  };

  return (
    <div className="all-users">
      <Button
        type="button"
        sx={{
          width: 200,
          height: 45,
          backgroundColor: "#4493c5",
          ":hover": { backgroundColor: "#4493c5" },
        }}
        variant="contained"
        color="primary"
        onClick={handleAddAdmin}
        startIcon={<SaveAltIcon />}
      >
        Add Admin
      </Button>
      <hr></hr>
      <AllUsersTable></AllUsersTable>
    </div>
  );
};

export default Users;
