import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";
import "./style.css";

const ReviewRating = ({
  handleRatingClick,
  initialValue,
  readOnly,
  className,
}) => {
  return (
    <Rating
      className={className}
      onClick={(val) => handleRatingClick(val)}
      emptySymbol={
        <FontAwesomeIcon
          className="icon_color_gray"
          icon="fa-regular fa-star"
        />
      }
      fullSymbol={[
        <FontAwesomeIcon className="icon_color_blue" icon="fa-solid fa-star" />,
      ]}
      // fractions={2}
      initialRating={initialValue}
      readonly={readOnly}
    ></Rating>
  );
};

export default ReviewRating;
