import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MuiInput from "../../../components/others/MuiInput";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { ValidateEditReview } from "../../../utilities/validator";
import { EditUserReview } from "../../../store/modules/userSlice";

const EditReview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const user = useSelector((state) => state.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({
    review_id: 0,
    feedback: "",
    rating: "",
    wwa: "",
  });
  useEffect(() => {
    if (
      location.state &&
      location.state.review_id &&
      user.allUsersReviews.length > 0
    ) {
      const result = user.allUsersReviews.filter(
        (item) => item.review_id == location.state.review_id
      );

      setData({
        ...data,
      });

      if (result.length > 0) {
        setData({
          ...data,
          ["feedback"]: result[0].feedback,
          ["rating"]: result[0].rating,
          ["wwa"]: result[0].wwa,
          ["review_id"]: location.state.review_id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateEditReview(data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const submitForm = () => {
    try {
      dispatch(
        EditUserReview({
          data: data,
          user_token: account.user_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="edit_reiview">
      <h5 className="text-black">Edit Review</h5>
      <hr></hr>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <MuiInput
                label={"Rating"}
                type={"number"}
                variant={"standard"}
                min={0}
                max={5}
                inputError={formError.rating}
                name={"rating"}
                inputValue={data.rating}
                handleChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <MuiInput
                type={"text"}
                label={"Feedback"}
                variant={"standard"}
                inputError={formError.feedback}
                name={"feedback"}
                inputValue={data.feedback}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  minHeight: "50px",
                  minWidth: "200px",
                  backgroundColor: "#4493c5",
                  ":hover": { backgroundColor: "#58ace0" },
                }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditReview;
