import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SetReviewModal } from "../../store/modules/modalSlice";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "../rating/Rating";
import "./style.scss";
import { SubmitReview } from "../../store/modules/userSlice";
import { ValidateReview } from "../../utilities/validator";
import AntDesignButton from "../others/Button/AntDesignButton";

const BookingCompleteReviewModal = () => {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState({});
  const account = useSelector((state) => state.account);
  const [data, setData] = useState({
    feedback: "",
    rating: 0,
    wwa: false,
  });
  const handleClose = () => {
    dispatch(
      SetReviewModal({
        bitToSet: "locum_review_modal_open",
        bit_value: false,
      })
    );
  };

  const handleRatingClick = (val) => {
    setData((preVal) => ({
      ...preVal,
      rating: val,
    }));
    setFormError({ ...formError, rating: "" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateReview(data));
    setIsSubmitting(true);
  };
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  const submitForm = () => {
    try {
      dispatch(
        SubmitReview({
          user_token: account.user_token,
          reviewData: data,
          reviewInfo: modal.reviewData,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <Modal
        className="review_modal"
        backdrop="static"
        onEscapeKeyDown={(e) => {
          e.preventDefault();
        }}
        onBackdropClick={(e) => {
          e.preventDefault();
        }}
        centered={true}
        size="lg"
        onHide={handleClose}
        show={modal.locum_review_modal_open}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body style={{ padding: "0px" }}>
          <div className="review_modal_body">
            {/* <div className="review_modal_close">
              <IconButton onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </div> */}
            <form onSubmit={handleSubmit}>
              {modal.reviewData && modal.reviewData.reviewee_name && (
                <div className="alert alert-primary" role="alert">
                  <FontAwesomeIcon
                    className=""
                    icon="fa-solid fa-circle-info"
                  />
                  &nbsp; Your review for "{modal.reviewData.reviewee_name}" will
                  not be publically visible until he reviews you.
                </div>
              )}
              <div className="text-center">
                <h5 className="mb-3">Hi {modal.reviewData.reviewer_name}</h5>
                {/* <p>Your have completed a task  feedback will help us improve our website.</p> */}

                {modal.reviewData && modal.reviewData.reviewee_name && (
                  <p>
                    How was your experience working with&nbsp;
                    <b>{modal.reviewData.reviewee_name}</b>? Please provide
                    rating and feedback accordingly.
                  </p>
                )}
              </div>
              <div className="mt-3">
                <textarea
                  className="form-control"
                  rows="4"
                  cols="50"
                  placeholder="Enter your feedback"
                  name="feedback"
                  value={data.feedback}
                  onChange={handleChange}
                ></textarea>
                <small className="text-danger">{formError.feedback}</small>
              </div>
              <div className="mt-4 mb-0 text-center">
                <Rating
                  className="rating_size_big"
                  readOnly={false}
                  handleRatingClick={handleRatingClick}
                  initialValue={data.rating}
                ></Rating>
              </div>
              <div className="text-center">
                <small className="input_errors">{formError.rating}</small>
              </div>
              <div className="mt-4 text-center">
                <AntDesignButton
                  btn_label={"Submit Review"}
                  htmlType="submit"
                  handleClick={() => {}}
                ></AntDesignButton>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BookingCompleteReviewModal;
