import React from "react";
import MapTest from "../googlemaps/MapTest";
import { useSelector, useDispatch } from "react-redux";
import { SetTaskMapModal } from "../../store/modules/modalSlice";
import Modal from "react-bootstrap/Modal";
import "./style.scss";

const MapModal = ({ showGetDirection }) => {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      SetTaskMapModal({
        mapModalOpen: false,
        location_data: {},
      })
    );
  };

  return (
    <div className="map_modal_main">
      <Modal
        className="map_modal"
        centered={true}
        size="lg"
        onHide={handleClose}
        show={modal.mapModalOpen}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body style={{ padding: "0px" }}>
          <MapTest
            lat={modal.locationData.lat}
            lng={modal.locationData.lng}
            draggable={modal.locationData.draggable}
            clickable={modal.locationData.clickable}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            showGetDirection={modal.locationData.showGetDirection}
            showRadiusOptions={modal.locationData.showRadiusOptions}
            fullscreenControl={false}
            mapTypeControl={false}
            streetViewControl={false}
            zoom={15}
            skeletonBorderRadius={true}
          ></MapTest>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MapModal;
