/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetSiteContent } from "../../store/modules/contentSlice";
import { CAPITALIZE } from "../../utilities/helper";
import { SITE_SECTIONS } from "../../utilities/enumerations";
import Button from "@mui/material/Button";
import EnhancedTableFooter from "./components/EnhancedTableFooter";
import EnhancedTableHead from "./components/EnhancedTableHead";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const EnhancedTable = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { site_content, site_content_count } = useSelector(
    (state) => state.content
  );
  const [data, setData] = useState({
    section: "",
    name: null,
    rows_per_page: 10,
    start: 0,
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Id",
    },
    {
      id: "admin_name",
      numeric: true,
      disablePadding: false,
      label: "Admin Name",
    },
    {
      id: "section",
      numeric: true,
      disablePadding: false,
      label: "Section",
    },
    {
      id: "key",
      numeric: true,
      disablePadding: false,
      label: "Key",
    },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "Value",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  useEffect(() => {
    dispatch(
      GetSiteContent({
        user_token: account.user_token,
        data,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      GetSiteContent({
        user_token: account.user_token,
        data,
      })
    );
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleClearFilters = () => {
    setData({
      section: null,
      name: null,
      rows_per_page: 10,
      start: 0,
    });
  };

  // * Handle pagiantion before click
  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };

  // * Handle pagiantion next click
  const handleNextClick = () => {
    if (site_content_count > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  // * Handle edit settings click
  const handleEditSetting = (id) => {
    navigate("add_content", {
      state: {
        edit_content: true,
        item_id: id,
      },
    });
  };

  // * Handle delete settings click
  const handleDeleteSetting = (id) => {
    alert("Needs Implementation");
  };

  return (
    <div className="">
      {/* Filters */}
      <div className="row m-0">
        <div className="col " style={{ paddingLeft: "0px" }}>
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Section
            </label>
            <FormControl fullWidth>
              <Select
                name="section"
                value={data.status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                {SITE_SECTIONS.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mt-2 d-flex">
          <Button
            onClick={handleClearFilters}
            type="button"
            sx={{
              marginTop: 2,
              width: 200,
              height: 38,
              backgroundColor: "#747e8c",
              ":hover": { backgroundColor: "#747e8c" },
            }}
            variant="contained"
            color="info"
          >
            Clear All Filters
          </Button>
        </div>
      </div>
      <hr></hr>

      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={headCells} />
              <TableBody>
                {site_content && site_content.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  site_content &&
                  site_content.length > 0 &&
                  site_content.map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.admin_name}</TableCell>
                        <TableCell>{CAPITALIZE(item.section)}</TableCell>
                        <TableCell>{item.key.trim()}</TableCell>
                        <Tooltip title={item.value} placement="top-start">
                          <TableCell>
                            {item.value != null
                              ? item.value.substring(0, 20) + "..."
                              : ""}
                          </TableCell>
                        </Tooltip>
                        <TableCell align="">
                          <Tooltip title="Edit" placement="right">
                            <IconButton
                              color="info"
                              aria-label="delete"
                              onClick={() => handleEditSetting(item.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" placement="right">
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => handleDeleteSetting(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableFooter
            start={data.start}
            rows_per_page={data.rows_per_page}
            total_results_count={site_content_count}
            handleRowCountChange={handleChange}
            handleBeforeClick={handleBeforeClick}
            handleNextClick={handleNextClick}
          ></EnhancedTableFooter>
        </Paper>
      </Box>
    </div>
  );
};

export default EnhancedTable;
