import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MuiInput from "../../../components/others/MuiInput";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { ValidateSiteContent } from "../../../utilities/validator";
import MuiDropdown from "../../../components/others/MuiDropdown";
import { SITE_SECTIONS } from "../../../utilities/enumerations";
import { AddSiteContent } from "../../../store/modules/contentSlice";
import TextEditor from "../../../components/others/TextEditor/TextEditor";
import { Col, Divider, Row } from "antd";

const SiteContent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { site_content } = useSelector((state) => state.content);
  const account = useSelector((state) => state.account);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({
    key: "",
    value: "",
    section: "",
  });

  useEffect(() => {
    setData({
      key: "",
      value: "",
      section: "",
    });
    if (location.state?.edit_content) {
      const filteredData =
        site_content &&
        site_content.length > 0 &&
        site_content.filter((item) => item.id === location.state.item_id);
      if (filteredData && filteredData.length > 0)
        setData({
          ...data,
          key: filteredData[0].key,
          value: filteredData[0].value,
          section: filteredData[0].section,
        });
    }
  }, []);

  // * Check if not errors
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  // * Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  // * Text editor change
  const handleTextEditorChange = (value) => {
    setData((preval) => {
      return {
        ...preval,
        ["value"]: value,
      };
    });
  };

  // * Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateSiteContent(data));
    setIsSubmitting(true);
  };

  // * Final form submit
  const submitForm = () => {
    try {
      dispatch(
        AddSiteContent({
          data: data,
          user_token: account.user_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="dashboard_main">
      <Col span={24}>
        <h5 className="text-black">Add/Update Content</h5>
        <Divider></Divider>
        <form onSubmit={handleSubmit}>
          <Row gutter={[40, 40]} className="my-4">
            <Col md={24} xl={12}>
              <MuiDropdown
                isRequired={true}
                label={"Section"}
                name={"section"}
                inputValue={data.section}
                inputError={formError.section}
                handleChange={handleChange}
                options={SITE_SECTIONS}
              ></MuiDropdown>
            </Col>
            <Col md={24} xl={12}>
              <MuiInput
                isRequired={true}
                type={"text"}
                label={"Key"}
                variant={"standard"}
                inputError={formError.key}
                name={"key"}
                inputValue={data.key}
                handleChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="my-4">
            <Col span={24}>
              <TextEditor
                inputValue={data.value}
                label="Description"
                isRequired={true}
                inputError={formError.value}
                handleTextEditorChange={handleTextEditorChange}
              ></TextEditor>
            </Col>
          </Row>
          <Row className="my-4">
            <Col span={24}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  minHeight: "50px",
                  minWidth: "200px",
                  backgroundColor: "#4493c5",
                  ":hover": { backgroundColor: "#58ace0" },
                }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};
export default SiteContent;
