import React from "react";
import SpalshScreen from "../components/SplashScreen";
import { useSelector } from "react-redux";
import PrimaryNavbar from "../components/navbar/PrimaryNavbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";

const WithNavFooter = () => {
  const content = useSelector((state) => state.content);

  return (
    <div>
      {!content.is_content_fetched ? (
        <SpalshScreen />
      ) : (
        <>
          <header>
            <PrimaryNavbar />
          </header>
          <main>
            <Outlet />
          </main>
          <footer>
            <Footer />
          </footer>
        </>
      )}
    </div>
  );
};

export default WithNavFooter;
