/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { DeleteUser, GetAllUsers } from "../../store/modules/userSlice";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ContactsIcon from "@mui/icons-material/Contacts";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import "./style.scss";
import EnhancedTableFooter from "./components/EnhancedTableFooter";
import EnhancedTableHead from "./components/EnhancedTableHead";
import { GET_TABLE_COLUMNS } from "../../utilities/helper";


export default function EnhancedTable() {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { allUsers, allUsersCount } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const columnLabels = "User Id, Name, Email, Is Email Verified, Role, Created At, Actions";

  const [data, setData] = useState({
    is_deleted: false,
    role: null,
    rows_per_page: 10,
    start: 0,
  });

  useEffect(() => {
    dispatch(GetAllUsers({ user_token: account.user_token, data }));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    dispatch(GetAllUsers({ user_token: account.user_token, data }));
  }, [data]);

  const handleClearFilters = () => {
    setData({
      is_deleted: null,
      role: null,
      rows_per_page: 10,
      start: 0,
    });
  };

  const deleteUser = (id) => {
    confirmAlert({
      message: "Are you sure to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(DeleteUser({ user_token: account.user_token, userId: id })),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const editUserProfile = (val) => {
    navigate(
      `/admin/edit_user_profile/${val.id}`,
      {
        state: {
          user_id: val.id,
          role: val.role,
          userName: val.userName,
        },
      },
      true
    );
  };

  // const recoverUser = () => {
  //   alert("Needs Implementation");
  // };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };
  const handleNextClick = () => {
    if (allUsersCount > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  const showUserProfile = (id) => {
    navigate(
      `/admin/user_profile/${id}`,
      {
        state: {
          user_id: id,
        },
      },
      true
    );
  };

  return (
    <div className="">
      {/* Filters */}
      <div className="row m-0">
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingLeft: "0px" }}
        >
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Status
            </label>
            <FormControl fullWidth>
              <Select
                name="is_deleted"
                value={data.is_deleted}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={true}>Deleted</MenuItem>
                <MenuItem value={false} selected>
                  Active
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingRight: "0px  " }}
        >
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Role
            </label>
            <FormControl fullWidth>
              <Select
                name="role"
                value={data.role}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={"locum"}>Locum</MenuItem>
                <MenuItem value={"practice"}>Practice</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mt-2 d-flex">
          <Button
            onClick={handleClearFilters}
            type="button"
            sx={{
              marginTop: 2,
              width: 200,
              height: 38,
              backgroundColor: "#747e8c",
              ":hover": { backgroundColor: "#747e8c" },
            }}
            variant="contained"
            color="info"
          >
            Clear All Filters
          </Button>
        </div>
      </div>
      <hr></hr>

      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={GET_TABLE_COLUMNS(columnLabels)} />
              <TableBody>
                {allUsers && allUsers.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={columnLabels.split(",").length}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  allUsers && allUsers.length > 0 && allUsers.map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.user_name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">
                          {row.email_verified_at ? (
                            <spam className="text-success">Yes</spam>
                          ) : (
                            <spam className="text-danger">No</spam>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row.role.charAt(0).toUpperCase() +
                            row.role.slice(1)}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(row.created_at)
                            .toLocaleDateString()
                            .replaceAll("/", "-")}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="Show Profile" placement="top">
                            <IconButton
                              color="info"
                              aria-label="delete"
                              onClick={() => showUserProfile(row.id)}
                            >
                              <ContactsIcon />
                            </IconButton>
                          </Tooltip>
                          {row.is_deleted == 0 && (
                            <Tooltip title="Edit Profile" placement="top">
                              <IconButton
                                color="success"
                                aria-label="delete"
                                onClick={() =>
                                  editUserProfile({
                                    id: row.id,
                                    role: row.role,
                                    userName: row.user_name,
                                  })
                                }
                                disabled={
                                  parseInt(row.id) === 1000 ? true : false
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {row.is_deleted == 0 ? (
                            <Tooltip title="Delete User" placement="top">
                              <IconButton
                                color="error"
                                aria-label="delete"
                                onClick={() => deleteUser(row.id)}
                                disabled={
                                  parseInt(row.id) === 1000 ? true : false
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <></>
                            // <Tooltip title="Recover User" placement="top">
                            //   <IconButton
                            //     color="success"
                            //     aria-label="delete"
                            //     onClick={() => recoverUser(row.id)}
                            //     disabled={
                            //       parseInt(row.id) === 1000 ? true : false
                            //     }
                            //   >
                            //     <DeleteSweepIcon />
                            //   </IconButton>
                            // </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableFooter
            start={data.start}
            rows_per_page={data.rows_per_page}
            total_results_count={allUsersCount}
            handleRowCountChange={handleChange}
            handleBeforeClick={handleBeforeClick}
            handleNextClick={handleNextClick}
          ></EnhancedTableFooter>
        </Paper>
      </Box>
    </div>
  );
}
