import React from "react";
import AllQueriesTable from "../../../components/tables/AllQueriesTable";
import "./style.css";

const Queries = () => {
  return (
    <div className="user_queries">
      <h5>Queries</h5>
      <hr></hr>
      <AllQueriesTable></AllQueriesTable>
    </div>
  );
};

export default Queries;
