import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MuiInput from "../../../components/others/MuiInput";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { ValidateAddSetting } from "../../../utilities/validator";
import { AddAdminSetting } from "../../../store/modules/userSlice";

const AddSetting = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const user = useSelector((state) => state.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({
    key: "",
    value: "",
  });

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateAddSetting(data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const submitForm = () => {
    try {
      dispatch(
        AddAdminSetting({
          data: data,
          user_token: account.user_token,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <MuiInput
                type={"text"}
                label={"Key"}
                variant={"standard"}
                inputError={formError.key}
                name={"key"}
                inputValue={data.key}
                handleChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12">
              <MuiInput
                type={"text"}
                label={"Value"}
                variant={"standard"}
                inputError={formError.value}
                name={"value"}
                inputValue={data.value}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  minHeight: "50px",
                  minWidth: "200px",
                  backgroundColor: "#4493c5",
                  ":hover": { backgroundColor: "#58ace0" },
                }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddSetting;
