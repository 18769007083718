import React from "react";
import SiteContentTable from "../../../components/tables/SiteContentTable";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useNavigate } from "react-router-dom";
import AntDesignButton from "../../../components/others/Button/AntDesignButton";
import { Col, Divider, Row } from "antd";
import "./style.css";

const AdminSettings = () => {
  const navigate = useNavigate();
  const handleClickOpenSiteContentModal = () => {
    navigate("add_content");
  };

  return (
    <Row className="admin_settings">
      <Col span={24}>
        <h5 className="text-black">Add/Update Content</h5>
        <Divider className=" bg-gray-800" />
        <AntDesignButton
          btn_label="Add/Update"
          handleClick={handleClickOpenSiteContentModal}
          startIcon={<SaveAltIcon />}
          icon_name="material-symbols:upload"
          icon_size={[20, 20]}
        />
        <br></br>
        <br></br>
        <h5 className="text-black">All Settings</h5>
        <SiteContentTable></SiteContentTable>
      </Col>
    </Row>
  );
};

export default AdminSettings;
