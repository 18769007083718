import { Card, Col, Divider, Row } from "antd";
import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import AntDesignButton from "../others/Button/AntDesignButton";
import { useDispatch, useSelector } from "react-redux";
import {
  DeletePaymentMethod,
  MakePaymentMethodDefault,
} from "../../store/modules/paymentSlice";

const PaymentMethodCard = ({
  cvc,
  expiry,
  focus,
  name,
  number,
  issuer,
  payment_method_id,
  stripe_customer_id,
  is_default,
  show_actions = true,
}) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const handleMakePaymentMethodDefault = () => {
    if (!payment_method_id || !stripe_customer_id) {
      return;
    }
    dispatch(
      MakePaymentMethodDefault({
        user_token: account.user_token,
        payment_method_id: payment_method_id,
        stripe_customer_id: stripe_customer_id,
      })
    );
  };

  const handlePaymentMethodDelete = () => {
    if (!payment_method_id || !stripe_customer_id) {
      return;
    }
    dispatch(
      DeletePaymentMethod({
        user_token: account.user_token,
        payment_method_id: payment_method_id,
        stripe_customer_id: stripe_customer_id,
      })
    );
  };

  return (
    <Card hoverable className="payment_method_card">
      <Cards
        issuer={issuer}
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={number}
        preview={true}
      />
      {show_actions && (
        <Row className="actions">
          <Col span={24}>
            <Row gutter={[10, 0]}>
              <Col span={12}>
                <AntDesignButton
                  disabled={is_default === "y" ? true : false}
                  margin_y={[0, 0]}
                  block={true}
                  btn_label="Make Default"
                  handleClick={() => {
                    handleMakePaymentMethodDefault();
                  }}
                />
              </Col>

              <Col span={12}>
                <AntDesignButton
                  danger={true}
                  block={true}
                  btn_label="Delete"
                  handleClick={() => {
                    handlePaymentMethodDelete();
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default PaymentMethodCard;
