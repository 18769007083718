import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";

const useOneSignalClient = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const connectClient = async () => {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        console.log("%c OneSignal initialization success", "color: green;");
        setInitialized(true);
      });
    };

    if (!initialized && !window.OneSignal) {
      connectClient().catch((err) => {
        console.log(`%c ${err}`, "color: red;");
      });
    }
  }, [initialized]);

  return true;
};

export default useOneSignalClient;
