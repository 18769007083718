import React from "react";
import Rating from "../rating/Rating";
import Profile from "../../assets/images/profile.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Col, Divider, Image, Row } from "antd";
import dayjs from "dayjs";

const UserReview = ({
  index,
  reviewerImageUrl,
  rating,
  wwa,
  reviewerName,
  feedback,
  reviewerId,
  showNameBtn = false,
  created_at,
}) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const handleReviewerProfileLink = (id) => {
    navigate(
      `/${account.role}/user_profile/${id}`,
      {
        state: {
          user_id: id,
        },
      },
      true
    );
  };
  return (
    <Row key={index}>
      <Col span={24}>
        <Card hoverable className="user_review_card" key={index}>
          <Row
            className="reviewer-profile-img"
            style={{ marginTop: "20px" }}
            justify="center"
          >
            <Col className="image_container">
              {reviewerImageUrl != null && reviewerImageUrl !== "" ? (
                <Image
                  width={200}
                  src={
                    process.env.REACT_APP_API_PROFILE_IMAGES_URI +
                    reviewerImageUrl
                  }
                  className="card-img-top"
                  alt="..."
                />
              ) : (
                <Image width={200} src={Profile} />
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <div className="card-body text-center">
            <div className="text-center mb-2">
              <Rating readOnly={true} initialValue={rating}></Rating>
            </div>
            <p className="card-text m-0 mb-2">"{feedback}"</p>
            {showNameBtn ? (
              <p className="text-muted m-0">
                <button
                  type="button"
                  onClick={() => {
                    handleReviewerProfileLink(reviewerId);
                  }}
                  className="reviewer_name_btn"
                >
                  {reviewerName}
                </button>
              </p>
            ) : (
              <p className="text-muted m-0">{reviewerName}</p>
            )}
            <p>{dayjs(created_at).format("D-MMMM-YYYY")}</p>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default UserReview;
