import React, { useEffect, useState } from "react";
import "./resetpassword.css";
import Reset from "assets/images/synchronize.png";
import Correct from "assets/images/correct.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import Input from "components/others/Input";
import {
  ResetPassword,
  SetAccountSliceBits,
} from "store/modules/accountSlice";
import { ValidateResetpassword } from "utilities/validator";

const Resetpassword = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [state, setstate] = useState(false);
  const [data, setData] = useState({
    password: "",
    c_password: "",
    token: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  useEffect(() => {
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    const token = urlParams.get("token");
    if (token) {
      setData({ ...data, ["token"]: token });
    }
  }, []);

  useEffect(() => {
    if (account.isPasswordReset && state) {
      setData({
        password: "",
        c_password: "",
        token: "",
      });
      setResetSuccess(true);
      dispatch(
        SetAccountSliceBits({
          bitToSet: "isPasswordReset",
          value: false,
        })
      );
    }
  }, [account.isPasswordReset]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateResetpassword(data));
    setIsSubmitting(true);
  };
  const submitForm = () => {
    try {
      dispatch(ResetPassword(data));
      setstate(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="container resetpassword">
        <div className="row">
          <div className="col-lg-6 m-auto resetpassword-card">
            <div className="text-center mt-2">
              <img
                src={resetSuccess ? Correct : Reset}
                className=""
                alt="lock.png"
              ></img>
              {!resetSuccess ? (
                <h4 className="mt-3">Forgotten your password?</h4>
              ) : (
                <p className="mt-5">
                  Password reset successfull. Please login again with new
                  password.
                </p>
              )}
            </div>
            {resetSuccess ? (
              <div>
                <NavLink
                  to="/"
                  className="btn btn-success btn-link m-auto d-block  mt-5 mb-3"
                >
                  Home
                </NavLink>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mt-5">
                  <Input
                    label="Password"
                    bold={false}
                    id="password"
                    placeholder="Enter new password"
                    inputError={formError.password}
                    inputValue={data.password}
                    type="text"
                    handleChange={handleChange}
                    name="password"
                  ></Input>
                  <Input
                    label="Confirm Password"
                    bold={false}
                    id="c_password"
                    placeholder="Enter new password"
                    inputError={formError.c_password}
                    inputValue={data.c_password}
                    type="text"
                    handleChange={handleChange}
                    name="c_password"
                  ></Input>
                  <div className="form-group mt-5">
                    <button
                      className="btn btn-success d-block w-100 btn-submit"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
