import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  backDropOpen: false,
};

const backDropSlice = createSlice({
  name: "backdrop",
  initialState,
  reducers: {
    SetBackDrop(state, { payload }) {
      return {
        ...state,
        backDropOpen:
          typeof payload === "object" ? payload.backDropOpen : payload,
      };
    },
  },
});

export const { SetBackDrop } = backDropSlice.actions;
export default backDropSlice.reducer;


