import React, { useEffect, useMemo, useState } from 'react'
import { StreamChat } from 'stream-chat';
import { createContext } from 'react'
import MESSAGE_ALERT from "assets/audio/message_alert.mp3";
import { SetAccountSliceBits } from 'store/modules/accountSlice';
import { useSelector, useDispatch } from 'react-redux'


const GetStreamContext = createContext(undefined);

const GetStreamProvider = ({ children }) => {

    const { user_chat_id, user_name, userId, user_chat_token } = useSelector((state) => state.account)
    const [userConnectionPromise, setUserConnectionPromise] = useState(null);
    const [userChannels, setUserChannels] = useState([])
    const [audio] = useState(new Audio(MESSAGE_ALERT));
    const dispatch = useDispatch();


    const client = useMemo(() => {
        if (user_chat_id && userId) {
            return new StreamChat(process.env.REACT_APP_STREAM_CHAT_API_KEY);
        }
    }, [userId, user_chat_id]);


    useEffect(() => {

        const user = {
            id: user_chat_id,
            name: user_name,
            role: "user",
        };

        let didUserConnectInterrupt = false;
        const connectUser = async () => {
            const connectionPromise = client
                .connectUser(user, user_chat_token)
                .then(() => {
                    if (!didUserConnectInterrupt) {
                        console.log("%c Stream chat user connected", "color: green;");
                    }
                });
            if (connectionPromise) {
                setUserConnectionPromise(connectionPromise);
            }
        };

        const getChannels = async () => {
            if (!client) {
                return;
            }
            const filter = {
                type: "messaging",
                members: { $in: [user.id] },
            };
            const sort = [{ last_message_at: -1 }];
            const channels = await client.queryChannels(filter, sort, {
                watch: false, // this is the default
                state: true,
            });
            console.log("%c Stream chat channels connected", "color: green;");
            setUserChannels(channels)
            channels.forEach((element) => {
                element.on((event) => {
                    console.log('Stream chat event : ', event.type)
                    switch (event.type) {
                        case "message.new":
                            if (event.message.user.id !== user_chat_id) {
                                dispatch(
                                    SetAccountSliceBits({
                                        bitToSet: "new_message",
                                        value: true,
                                    })
                                );
                                dispatch(
                                    SetAccountSliceBits({
                                        bitToSet: "total_unread_count",
                                        value: event.total_unread_count,
                                    })
                                );
                                if (Notification.permission === "granted") {
                                    audio.play();
                                }
                            }
                            break;


                        case "notification.message_new":
                            if (event.message.user.id !== user_chat_id) {
                                dispatch(
                                    SetAccountSliceBits({
                                        bitToSet: "new_message",
                                        value: true,
                                    })
                                );
                                dispatch(
                                    SetAccountSliceBits({
                                        bitToSet: "total_unread_count",
                                        value: event.total_unread_count,
                                    })
                                );
                                if (Notification.permission === "granted") {
                                    audio.play();
                                }
                            }
                            break;
                        case "message.read":
                            if (event.user.id === user_chat_id) {
                                dispatch(
                                    SetAccountSliceBits({
                                        bitToSet: "new_message",
                                        value: false,
                                    })
                                );
                                dispatch(
                                    SetAccountSliceBits({
                                        bitToSet: "total_unread_count",
                                        value: event.total_unread_count ? event.total_unread_count : 0,
                                    })
                                );
                            }
                            break;
                        default:
                            break;
                    }
                });
            });
        };


        if (client) {
            connectUser().catch((err) => {
                console.log(`%c ${err}`, "color: red;");
            });
        }
        if (client) {
            getChannels().catch((err) => {
                console.log(`%c ${err}`, "color: red;");
            });
        }

        return () => {
            didUserConnectInterrupt = true;
            // wait for connection to finish before initiating closing sequence
            if (userConnectionPromise) {
                userConnectionPromise
                    .then(() => client.disconnectUser())
                    .then(() => {
                        console.log("%c Stream chat connection closed", "color: orange;");
                    });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user changes
    }, [client, dispatch, user_chat_id, user_chat_token, user_name])


    return (

        <GetStreamContext.Provider value={{ client, userChannels }}>
            {children}
        </GetStreamContext.Provider>

    )
}

export { GetStreamProvider, GetStreamContext }