import React from "react";
import { Collapse, Text } from "@nextui-org/react";
import { GET_SITE_CONTENT } from "../../utilities/helper";
import { useSelector } from "react-redux";
import { Container, Col, Row } from 'react-bootstrap'
import "./style.scss";

const Faq = () => {
  const content = useSelector((state) => state.content);

  return (
    <section className="faq_main">
      <Container fluid='lg'>
        <Row className="faq_section">
          <Col sm={12} md={6}
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "faq",
                "faq_header"
              ),
            }}
          ></Col>
          <Col sm={12} md={6}>
            <Collapse.Group shadow>
              <Collapse
                title={
                  <Text span>
                    How do I add a new question & answer? (Demo)
                  </Text>
                }
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Text>
              </Collapse>
              <Collapse
                title={
                  <Text span>
                    Can I insert an image, video, or gif in my FAQ? (Demo)
                  </Text>
                }
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Text>
              </Collapse>
              <Collapse
                title={
                  <Text span>
                    How do I edit or remove the “FAQ” title? (Demo)
                  </Text>
                }
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Text>
              </Collapse>
              <Collapse
                title={
                  <Text span>
                    How do I edit or remove the “FAQ” title? (Demo)
                  </Text>
                }
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Text>
              </Collapse>
            </Collapse.Group>
          </Col>
        </Row>


        <Row className="billing_section">
          <Col sm={12} md={6}
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "faq",
                "faq_billing_header"
              ),
            }}
          ></Col>
          <Col sm={12} md={6}
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "faq",
                "faq_billing_para"
              ),
            }}
          ></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faq;
