import React from "react";
import ImageUploading from "react-images-uploading";
import Button from "@mui/material/Button";

const UploadImages = ({ imageUplaod }) => {
  const maxNumber = 69;

  return (
    <ImageUploading
      multiple={false}
      onChange={imageUplaod}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className="upload__image-wrapper">
          <Button
            style={isDragging ? { color: "red" } : undefined}
            onClick={onImageUpload}
            {...dragProps}
            sx={{
              width: 200,
              height: 45,
            }}
          >
            <i className="fa fa-cloud-upload"></i>&nbsp; Upload Image
          </Button>
        </div>
      )}
    </ImageUploading>
  );
};

export default UploadImages;
