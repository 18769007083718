import React from "react";
import TextField from "@mui/material/TextField";
import { usePlacesWidget } from "react-google-autocomplete";
import Label from "../Label/Label";

const AddressInput = ({
  handlePlaceChange,
  label,
  isTextArea = false,
  inputError,
  inputValue,
  handleChange,
  name,
  type,
  id,
  bold,
  disabled = false,
  variant = "standard",
  isRequired,
}) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      handlePlaceChange(place);
    },
    options: {
      types: [],
      fields: ["ALL"],
      strictBounds: true,
      componentRestrictions: {
        country: ["au"],
      },
    },
  });

  return (
    <>
      <Label label={label} isRequired={isRequired} bold={bold}></Label>
      <TextField
        error={inputError && inputError !== "" ? true : false}
        id={id}
        type={type}
        variant={variant}
        name={name}
        value={inputValue ? inputValue : ""}
        onChange={(event) => handleChange(event)}
        fullWidth={true}
        disabled={disabled}
        inputProps={{
          style: { height: isTextArea ? "70px" : "35px" },
          autoComplete: "new-password",
        }}
        InputLabelProps={{
          shrink: true,
        }}
        autoFocus={false}
        autoComplete="on"
        helperText={inputError != null || inputError !== "" ? inputError : " "}
        inputRef={ref}
      />
    </>
  );
};

export default AddressInput;
