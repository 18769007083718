import React from "react";
import "./style.scss";
import { Spin } from "antd";

const IsProcessing = () => {
  return (
    <div className="row m-0 is_processing">
      <Spin size="large" />
    </div>
  );
};

export default IsProcessing;
