import React from "react";
import "./style.scss";

const NoResultsFound = ({ text = "No results found." }) => {
  return (
    <div className="no_results_found">
      <p>{text}</p>
    </div>
  );
};

export default NoResultsFound;
