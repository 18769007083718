import React from "react";
import { Button, Result } from "antd";
import "./style.scss";

const WentWrong = ({ error }) => {

  const handleClick = () => {
    window.location.href = "/";
  };

  return (
    <div className="error_fall_back">
      <div className="fall_back_card">
        <Result
          status="500"
          title="Something Went Wrong!"
          subTitle={<p className="mb-4 mt-2 text-danger">{error.message}</p>}
          extra={
            <Button
              onClick={handleClick}
              style={{
                backgroundColor: "#4493c5",
                ":hover": { backgroundColor: "#4493c5" },
              }}
              type="primary"
              key="console"
            >
              Homepage
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default WentWrong;
