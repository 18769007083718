import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

const initialState = {
  alertOpen: false,
  alertMessage: "",
  alertType: "",
  alertTitle: "",
};

const alertSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setAlert(state, { payload }) {
      return {
        ...state,
        alertOpen: payload.alertOpen,
        alertMessage: payload.alertMessage,
        alertType: payload.alertType,
        alertTitle: payload.alertTitle,
      };
    },
    ResetAlertSliceState(state, { payload }) {
      return {
        alertOpen: false,
        alertMessage: "",
        alertType: "",
        alertTitle: "",
      };
    },
  },
});

export const { setAlert, ResetAlertSliceState } = alertSlice.actions;
export default alertSlice.reducer;

//----------------------------------------------------------------

export function SetAlert(payload) {
  return (dispatch) => {
    dispatch(setAlert(payload));
  };
}
