import React from "react";
import "./style.css";
import Construction from "assets/images/under-construction.png";
import { useNavigate } from "react-router-dom";

const InProgress = (props) => {
  const { homePageBtn } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div id="inprogress-main">
      <div className="row inprogress">
        <div className="col-lg-12 m-auto text-center inprogressCard">
          <div className="mt-5 mb-5">
            <img alt="" src={Construction}></img>
          </div>
          <br></br>
          <p className="mb-4 mt-2 text-success">
            This page will be soon available !
          </p>
          {homePageBtn && (
            <button
              className="btn btn-primary btn-submit w-75 d-block m-auto mb-4"
              onClick={handleClick}
            >
              Home Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InProgress;
