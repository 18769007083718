/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { GetAllStripePayments } from "../../store/modules/paymentSlice";
import dayjs from "dayjs";
import EnhancedTableFooter from "./components/EnhancedTableFooter";
import EnhancedTableHead from "./components/EnhancedTableHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import { GET_TABLE_COLUMNS } from "../../utilities/helper";
import "./style.scss";

export default function AllPaymentsTable({ current_tab }) {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const nameRef = useRef();
  const { all_stripe_payments, stripe_payments_count } = useSelector(
    (state) => state.payment
  );

  const columnLabels =
    "Payment Id, Practice Name, Amount, Currency, Status, Subscription period, Receipt, Description, Created At";

  const [data, setData] = useState({
    status: null,
    practiceName: null,
    rows_per_page: 10,
    start: 0,
  });

  useEffect(() => {
    if (current_tab === "all_payments") {
      dispatch(
        GetAllStripePayments({
          user_token: account.user_token,
          data,
        })
      );
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleClearFilters = () => {
    setData({
      status: null,
      practiceName: null,
      rows_per_page: 10,
      start: 0,
    });
    nameRef.current.value = "";
  };

  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };
  const handleNextClick = () => {
    if (stripe_payments_count > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  const handleNameSearch = () => {
    setData((prevState) => ({
      ...prevState,
      practiceName: nameRef.current.value,
    }));
  };
  return (
    <div className="">
      {/* Filters */}
      <div className="row m-0">
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingLeft: "0px" }}
        >
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Status
            </label>
            <FormControl fullWidth>
              <Select
                name="status"
                value={data.status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={"requested"}>REQUESTED</MenuItem>
                <MenuItem value={"succeeded"}>ACCEPTED</MenuItem>
                <MenuItem value={"rejected"}>REJECTED</MenuItem>
                <MenuItem value={"refunded"}>REFUNDED</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingRight: "0px  " }}
        >
          <div className="form-group mt-2">
            <label className="form-label">Name</label>
            <div className="input-group mb-3 name_filter_group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter practice username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                ref={nameRef}
              ></input>
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={handleNameSearch}
              >
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mt-2 d-flex">
          <Button
            onClick={handleClearFilters}
            type="button"
            sx={{
              marginTop: 2,
              width: 200,
              height: 38,
              backgroundColor: "#747e8c",
              ":hover": { backgroundColor: "#747e8c" },
            }}
            variant="contained"
            color="info"
          >
            Clear All Filters
          </Button>
        </div>
      </div>
      <hr></hr>

      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={GET_TABLE_COLUMNS(columnLabels)} />
              <TableBody>
                {all_stripe_payments && all_stripe_payments.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={columnLabels.split(",").length}
                    >
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  all_stripe_payments &&
                  all_stripe_payments.length > 0 &&
                  all_stripe_payments.map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.payment_id}>
                        <TableCell>{item.id && item.id}</TableCell>
                        <TableCell>
                          {item.user_name && item.user_name}
                        </TableCell>
                        <TableCell>
                          {item.amount && "$" + parseInt(item.amount) / 100}
                        </TableCell>
                        <TableCell>{item.currency && item.currency}</TableCell>
                        <TableCell>
                          {item.status && (
                            <span
                              className={
                                item.status === "accepted"
                                  ? "text-success "
                                  : "text-warning"
                              }
                              style={{ textTransform: "uppercase" }}
                            >
                              {item.status}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.current_period_start && (
                            <p style={{ margin: "0px" }}>
                              <b>Start date : </b>
                              {dayjs(item.current_period_start).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          )}
                          {item.current_period_start && (
                            <>
                              <b>End date : </b>
                              {dayjs(item.current_period_end).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.receipt_url && (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.receipt_url}
                            >
                              <Icon
                                icon="iconamoon:invoice"
                                color="blue"
                                width="20"
                                height="20"
                              />
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.description && item.description}
                        </TableCell>

                        <TableCell>
                          {item.created_at &&
                            dayjs(item.created_at + "Z").format("DD-MMM-YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableFooter
            start={data.start}
            rows_per_page={data.rows_per_page}
            total_results_count={stripe_payments_count}
            handleRowCountChange={handleChange}
            handleBeforeClick={handleBeforeClick}
            handleNextClick={handleNextClick}
          ></EnhancedTableFooter>
        </Paper>
      </Box>
    </div>
  );
}
