import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import WentWrong from "pages/errorfallback/ErrorFallBack";
import "./style.scss";
import { GetStreamProvider } from "providers/getStreamProvider";
import { ConfigProvider } from 'antd';
import { LOG_ERRORS } from "utilities/helper";


function logErrorToService(error, info) {
  LOG_ERRORS(error, info);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={WentWrong} onError={logErrorToService}>
            <GetStreamProvider>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "4493c5"
                  },
                  components: {
                    Segmented: {
                      itemSelectedColor: "rgba(0, 0, 0, 0.06)",
                      itemActiveBg: "#4493c5",
                      itemSelectedBg: "#4493c5",
                    },
                    Badge: {
                      // colorBgContainer:"#4493c5"
                    }
                  }
                }}
              >
                <App />
              </ConfigProvider>
            </GetStreamProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
