import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

const PaymentInfoTable = ({payment}) => {
    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Statuss</strong></TableCell>
              <TableCell><strong>Amount</strong></TableCell>
              <TableCell><strong>Currency</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow

              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope="" >Available</TableCell>
              {payment.stripe_balance_data.available ? <TableCell scope="" >US ${parseInt(payment.stripe_balance_data.available[0].amount) / 100}</TableCell> : <TableCell scope="" ><CircularProgress size={20}/></TableCell>}
              {payment.stripe_balance_data.available ? <TableCell scope="" >{payment.stripe_balance_data.available[0].currency}</TableCell> : <TableCell scope="" ><CircularProgress size={20}/></TableCell>}
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope="" >Pending</TableCell>
              {payment.stripe_balance_data.pending ? <TableCell scope="" >US ${parseInt(payment.stripe_balance_data.pending[0].amount) / 100}</TableCell> : <TableCell scope="" ><CircularProgress size={20}/></TableCell>}
              {payment.stripe_balance_data.pending ? <TableCell scope="" >{payment.stripe_balance_data.pending[0].currency}</TableCell> : <TableCell scope="" ><CircularProgress size={20}/></TableCell>}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
}

export default PaymentInfoTable