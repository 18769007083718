import React, { useEffect } from "react";
// import Error from "../../../assets/images/error.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Result } from "antd";
import "./style.scss";
import { ROLES } from "../../utilities/enumerations";

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const handleClick = () => {
    navigate("/");
  };

  return account.user_token === "" &&
    (location.pathname.includes(ROLES.admin) ||
      location.pathname.includes(ROLES.locum) ||
      location.pathname.includes(ROLES.practice)) ? (
    <Navigate to="login" state={{ from: location }} replace></Navigate>
  ) : (
    <div className="error_page">
      <div className="error_page_card">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              style={{
                backgroundColor: "#4493c5",
                ":hover": { backgroundColor: "#4493c5" },
              }}
              type="primary"
              onClick={handleClick}
            >
              Back Home
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default ErrorPage;
