import axios from 'axios';

// Create an Axios instance with global configurations
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URI, // Set the base URL
    headers: {
        'Accept': 'application/json', // Define common headers
    },
});

// Helper function to add an optional Authorization header with a Bearer token
const addBearerToken = (config, bearer_token) => {
    config = config ?? {};
    config.headers = config.headers ?? {}; // Ensure headers is an object
    if (bearer_token) {
        config.headers['Authorization'] = `Bearer ${bearer_token}`;
    }
};

// HTTP GET request
export function httpGet(url, config = {}) {
    // Add Authorization header if a bearer_token is provided in the config
    addBearerToken(config, config.bearer_token);
    return axiosInstance.get(url, config);
}

// HTTP POST request
export function httpPost(url, data, config = {}) {
    // Add Authorization header if a bearer_token is provided in the config
    addBearerToken(config, config.bearer_token);
    return axiosInstance.post(url, data, config);
}

// HTTP PUT request
export function httpPut(url, data, config = {}) {
    // Add Authorization header if a bearer_token is provided in the config
    addBearerToken(config, config.bearer_token);
    return axiosInstance.put(url, data, config);
}

// HTTP PATCH request
export function httpPatch(url, data, config = {}) {
    // Add Authorization header if a bearer_token is provided in the config
    addBearerToken(config, config.bearer_token);
    return axiosInstance.patch(url, data, config);
}

// HTTP DELETE request
export function httpDelete(url, config = {}) {
    // Add Authorization header if a bearer_token is provided in the config
    addBearerToken(config, config.bearer_token);
    return axiosInstance.delete(url, config);
}
