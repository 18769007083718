import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAllUsersReviews } from "../../../store/modules/userSlice";
import UsersReviewsTable from "../../../components/tables/UsersReviewsTable";

const UsersReviews = (props) => {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  
//   useEffect(() => {
//     dispatch(GetAllUsersReviews({ user_token: account.user_token }));
//   }, []);

  return (
    <div className="users_reviews">
      <h5 className="text-black">Users Reviews</h5>
      <hr></hr>
      <UsersReviewsTable></UsersReviewsTable>
    </div>
  );
};

export default UsersReviews;
