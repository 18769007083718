import React, { Suspense } from "react";
import { Button, Tooltip, Spin } from "antd";

import { Icon } from "@iconify/react";
// import "./style.scss";

const AntDesignButton = ({
  tooltip_title,
  btn_label,
  handleClick,
  type = "primary",
  shape = "default",
  block = false,
  htmlType = "button",
  placement = "top",
  btn_height = "40",
  margin_y = [0, 0],
  margin_x = [0, 0],
  disabled = false,
  width,
  icon_name,
  size = "default",
  icon_size = [15, 20],
  icon_color = "white",
  backgroundColor = "#4493c5",
  danger = false,
}) => {
  return (
    <>
      <Suspense fallback={<Spin />}>
        {tooltip_title && tooltip_title !== "" ? (
          <Tooltip title={tooltip_title} placement={placement}>
            <Button
              danger={danger}
              size={size}
              disabled={disabled}
              style={{
                backgroundColor: danger || disabled ? "" : backgroundColor,
                marginTop: margin_y[0] + "px",
                marginBottom: margin_y[1] + "px",
                marginLeft: margin_x[0] + "px",
                marginRight: margin_x[1] + "px",
                height: btn_height + "px",
                width: width + "px",
              }}
              htmlType={htmlType}
              block={block}
              type={type}
              shape={shape}
              onClick={(event) => {
                handleClick(event);
              }}
            >
              {icon_name ? (
                <Icon
                  icon={icon_name}
                  color={icon_color}
                  height={icon_size[0]}
                  width={icon_size[1]}
                />
              ) : (
                btn_label
              )}
            </Button>
          </Tooltip>
        ) : (
          <Button
            danger={danger}
            size={size}
            disabled={disabled}
            style={{
              backgroundColor: danger || disabled ? "" : backgroundColor,
              marginTop: margin_y[0] + "px",
              marginBottom: margin_y[1] + "px",
              marginLeft: margin_x[0] + "px",
              marginRight: margin_x[1] + "px",
              height: btn_height + "px",
              width: width + "px",
              color: disabled ? "" : "white",
            }}
            htmlType={htmlType}
            block={block}
            type={type}
            shape={shape}
            onClick={(event) => {
              handleClick(event);
            }}
          >
            {icon_name && (
              <Icon
                icon={icon_name}
                color={icon_color}
                height={icon_size[0]}
                width={icon_size[1]}
              />
            )}
            &nbsp;
            <span className="align-text-top">{btn_label}</span>
          </Button>
        )}
      </Suspense>
    </>
  );
};

export default AntDesignButton;
