import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import AntDesignButton from "../../components/others/Button/AntDesignButton";
import "./style.scss";

const SignupOptions = () => {
  const navigate = useNavigate();

  return (
    <Row className="signup-options">
      <Col span={24}>
        <Row justify="center">
          <Col
            className="signup-options-card"
            xs={22}
            sm={16}
            md={22}
            lg={16}
            xl={12}
            xxl={10}
          >
            <h3 className="text text-black mb-5 font-">Sign Up</h3>

            <Row gutter={[20, 10]} justify="space-evenly">
              <Col xs={22} sm={20} md={11} className="user-signup-option-card">
                <p>
                  Are you a Locum looking to let Practices to know your
                  availability?
                </p>
                <AntDesignButton
                  handleClick={() => {
                    navigate("/signup/locum");
                  }}
                  btn_label="Sign up as Locum"
                  block={true}
                />
              </Col>
              <Col xs={22} sm={20} md={11} className="user-signup-option-card">
                <p>
                  Are you a Practice manager wanting to look for Locums
                  availability?
                </p>
                <AntDesignButton
                  btn_label="Sign up as Practice"
                  handleClick={() => {
                    navigate("/signup/practice");
                  }}
                  block={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignupOptions;
