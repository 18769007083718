// TODO move admin settings and site settings related things here
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { UpdateSettingsData } from "./accountSlice";

const initialState = {
  is_update_success: false,
  settings_data: []
};

const settingsSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    SetSettingsSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
    changeNotificationSettings(state, { payload }) {
      return {
        ...state,
        is_update_success: true,
        settings_data: payload.data
      }
    }
  },
});

export const { changeNotificationSettings, SetSettingsSliceBits } = settingsSlice.actions;
export default settingsSlice.reducer;

export function ChangeNotificationSettings(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user_notification_settings`,
        {
          notification_type: payload.notification_type,
          value: payload.value,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.user_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        SHOW_SUCCESS_TOAST(response);
        // dispatch(changeNotificationSettings(response.data));
        // Direcly update settings from here
        dispatch(UpdateSettingsData(response.data))
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

//----------------------------------------------------------------
