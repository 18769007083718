import React from "react";
import TextField from "@mui/material/TextField";
import Label from "./Label/Label";

const MuiInput = ({
  label,
  isTextArea = false,
  inputValue,
  inputError,
  name,
  type,
  className,
  id,
  placeholder,
  bold,
  handleChange,
  locationRef,
  infoText,
  step,
  min,
  disabled = false,
  readOnly = false,
  max,
  variant = "standard",
  showInfoText,
  isRequired,
}) => {
  return (
    <div>
      <Label label={label} isRequired={isRequired} bold={bold}></Label>
      {type === "text" && (
        <TextField
          error={inputError && inputError !== "" ? true : false}
          id={id}
          type={type}
          variant={variant}
          name={name}
          value={inputValue ? inputValue : ""}
          fullWidth={true}
          disabled={disabled}
          onChange={(event) => handleChange(event)}
          inputProps={{
            style: { height: variant === "standard" ? "35px" : "20px" },
            autoComplete: "new-password",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          autoFocus={false}
          autoComplete="on"
          helperText={
            inputError != null || inputError !== "" ? inputError : " "
          }
        />
      )}
      {type === "number" && (
        <TextField
          error={inputError && inputError !== "" ? true : false}
          id={id}
          type={type}
          name={name}
          value={inputValue ? inputValue : ""}
          variant={variant}
          fullWidth={true}
          disabled={disabled}
          autoFocus={false}
          autoComplete="off"
          onChange={(event) => handleChange(event)}
          InputProps={{
            inputProps: {
              min: min,
              max: max,
              step: step,
              style: { height: variant === "standard" ? "35px" : "20px" },
              autoComplete: "on",
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={
            inputError != null || inputError !== "" ? inputError : " "
          }
        />
      )}
    </div>
  );
};

export default MuiInput;
