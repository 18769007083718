import { createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from '../../services/httpService';
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { SetBackDrop } from "./backDropSlice";

const initialState = {
  sc_is_processing: false,
  is_free_trial_start_success: false,
  sub_cancel_success: false,
  is_purchase_subscription_success: false,
  subscription_lookup_data: {},
  stripe_subscription_details: {},
  default_payment_method: [],
  all_stripe_subscriptions: [],
  all_stripe_subscriptions_count: 0,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    ResetSubscriptionSliceState(state, { payload }) {
      return initialState;
    },
    startFreeTrial(state, { payload }) {
      return {
        ...state,
        is_free_trial_start_success: true,
        is_member: true,
        subscription_start_date:
          payload.data.subscription.free_trial_start_date,
        subscription_end_date: payload.data.subscription.free_trial_end_date,
        subscription_type: payload.data.subscription.type,
      };
    },

    startSubscription(state, { payload }) {
      return {
        ...state,
        is_purchase_subscription_success: true,
        subscription_lookup_data: payload.data.data.sub_lookup
      };
    },
    getSubscriptionDetails(state, { payload }) {
      return {
        ...state,
        stripe_subscription_details: payload.data.stripe_subscription,
        subscription_lookup_data: payload.data.subscription_lookup ? payload.data.subscription_lookup : {}
      };
    },
    getSubscriptionStatus(state, { payload }) {
      return {
        ...state,
        subscription_lookup_data: payload.data.subscription_status
      };
    },
    setAllStripeSubscriptions(state, { payload }) {
      return {
        ...state,
        all_stripe_subscriptions: payload.data.all_stripe_subscriptions,
        all_stripe_subscriptions_count: payload.data.all_stripe_subscriptions_count,
      };
    },
    cancelSubscription(state, { payload }) {
      return {
        ...state,
        subscription_lookup_data: payload.data.sub_lookup,
        sub_cancel_success: true,
        stripe_subscription_details: {}
      };
    },
    SetSubscriptionSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
    setDefaultPaymentMethod(state, { payload }) {
      return {
        ...state,
        default_payment_method: payload.data.default_payment_method,
        subscription_lookup_data: payload.data.subscription_lookup_data,
      };
    },
    purchaseSubFromDefaultPm(state, { payload }) {
      return {
        ...state,
        subscription_lookup_data: payload.data.sub_lookup,
        is_purchase_subscription_success: true,
      };
    },
  },
});

export const {
  ResetSubscriptionSliceState,
  SetSubscriptionSliceBits,
  getSubscriptionStatus,
  startSubscription,
  getSubscriptionDetails,
  cancelSubscription,
  setDefaultPaymentMethod,
  purchaseSubFromDefaultPm,
  setAllStripeSubscriptions
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

//----------------------------------------------------------------

// Common action creators
const showSuccessAndDispatch = (dispatch, action = null, response, showToast = false) => {
  showBackDropAndLoader(dispatch, false, false) // Hide the backdrop on success
  if (action) {
    dispatch(action(response.data));
  }
  if (showToast) {
    SHOW_SUCCESS_TOAST(response);
  }
};

const showErrorAndLog = (dispatch, error, action = null, showToast = true) => {
  showBackDropAndLoader(dispatch, false, false) // Hide the backdrop on success
  console.log(error);
  if (action) {
    dispatch(action(error));
  }
  if (showToast) {
    SHOW_ERROR_TOAST(error);
  }
};

const showBackDropAndLoader = (dispatch, setbackdrop = false, setLoader = false) => {
  dispatch(SetBackDrop(setbackdrop));
  dispatch(SetSubscriptionSliceBits({
    bitToSet: "sc_is_processing",
    value: setLoader,
  }))
};


export function GetSubscriptionStatus(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));

    try {
      const response = await httpGet('/api/get_subscription_status', {
        bearer_token: payload.user_token, // Pass the bearer_token in the config
      });

      showSuccessAndDispatch(dispatch, getSubscriptionStatus, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function StripeSubscription(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));

    try {
      const response = await httpPost('/api/stripe_subscription', {
        payment_method: payload.payment_method,
      }, {
        bearer_token: payload.user_token, // Pass the bearer_token in the config
      });

      showSuccessAndDispatch(dispatch, startSubscription, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error, null, true);
    }
  };
}

export function GetSubscriptionDetails(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false)

    try {
      const response = await httpGet('/api/get_subscription_details', {
        bearer_token: payload.user_token, // Pass the bearer_token in the config
      });
      showSuccessAndDispatch(dispatch, getSubscriptionDetails, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function CancelStripeSubscription(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));

    try {
      const response = await httpPost('/api/cancel_subscription', {
        subscription_id: payload.subscription_id,
      }, {
        bearer_token: payload.user_token, // Pass the bearer_token in the config
      });

      showSuccessAndDispatch(dispatch, cancelSubscription, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetDefaultPaymentMethod(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));

    try {
      const response = await httpGet('/api/get_default_payment_method', {
        bearer_token: payload.user_token, // Pass the bearer_token in the config
      });

      showSuccessAndDispatch(dispatch, setDefaultPaymentMethod, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function PurchaseSubFromDefaultPm(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));

    try {
      const response = await httpPost('/api/purchase_subscription_default_pm', {
        payment_method_id: payload.payment_method_id,
        stripe_customer_id: payload.stripe_customer_id,
      }, {
        bearer_token: payload.user_token, // Pass the bearer_token in the config
      });

      showSuccessAndDispatch(dispatch, purchaseSubFromDefaultPm, response, true);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}

export function GetAllStripeSubscriptions(payload) {
  return async (dispatch) => {
    showBackDropAndLoader(dispatch, false, payload.show_loader ? true : false)
    try {
      const response = await httpPost(
        `${process.env.REACT_APP_API_URI}/api/all_stripe_subscriptions`,
        {
          status: payload.data?.status,
          practiceName: payload.data?.practiceName,
          rows_per_page: payload.data?.rows_per_page,
          start: payload.data?.start,
        },
        {
          bearer_token: payload.user_token,
        }
      );

      showSuccessAndDispatch(dispatch, setAllStripeSubscriptions, response);
    } catch (error) {
      showErrorAndLog(dispatch, error);
    }
  };
}