import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../../utilities/enumerations";
import AdminProfileEdit from "./AdminProfileEdit";
import LocumProfileEdit from "../Locum/LocumProfileEdit";
import PracticeProfileEdit from "../Practice/PracticeProfileEdit";
import { GetProfileData } from "../../../store/modules/accountSlice";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";

const EditUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (
      account.is_logged_in &&
      account.user_token &&
      account.user_token !== "" &&
      account.role &&
      account.role !== ""
    ) {
      dispatch(
        GetProfileData({
          user_token: account.user_token,
          user_id:
            location.state && location.state.user_id
              ? location.state.user_id
              : null,
          show_loader: true,
        })
      );
    }
  }, []);

  return (
    <div className="admin_edit_user">
      {location.state &&
        location.state.role &&
        location.state.role === ROLES.admin && (
          <AdminProfileEdit
            profileName={location.state.userName}
            user_id={location.state.user_id}
          />
        )}
      {location.state &&
        location.state.role &&
        location.state.role === ROLES.locum && (
          <LocumProfileEdit
            profileName={location.state.userName}
            user_id={location.state.user_id}
          />
        )}
      {location.state &&
        location.state.role &&
        location.state.role === ROLES.practice && (
          <PracticeProfileEdit
            profileName={location.state.userName}
            user_id={location.state.user_id}
          />
        )}
    </div>
  );
};

export default EditUser;
