import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import './style.scss'

const ButtonCheckBox = ({
  handleCheckboxChange,
  name,
  label,
  id,
  value,
  isChecked,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <div className="checkbox-group d-inline">
        <input
          type="checkbox"
          className="btn-check"
          id={id}
          value={value}
          name={name}
          autoComplete="off"
          onChange={(event) => {
            handleCheckboxChange(event);
          }}
          onChangeCapture={(event) => {
            if (event.target.checked) {
              setChecked(true);
            } else {
              setChecked(false);
            }
          }}
          checked={isChecked ? true : false}
        ></input>

        <label
          className="btn btn-outline-primary btn-checkbox-oultine"
          htmlFor={id}
        >
          {isChecked || checked ? <CloseIcon /> : <CheckIcon />} &nbsp; {label}
        </label>
      </div>
    </>
  );
};

export default ButtonCheckBox;
