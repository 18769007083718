/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import EnhancedTableFooter from "./components/EnhancedTableFooter";
import EnhancedTableHead from "./components/EnhancedTableHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET_TABLE_COLUMNS } from "../../utilities/helper";
import "./style.scss";
import { GetAllStripeSubscriptions } from "../../store/modules/subscriptionSlice";
import { Button } from "antd";

export default function AllSubscriptionsTable({ current_tab }) {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const nameRef = useRef();
  const { all_stripe_subscriptions, all_stripe_subscriptions_count } =
    useSelector((state) => state.subscription);

  const columnLabels =
    "Subscription Id, Practice Name, Amount, Status, Subscription period, Created At, Actions";

  const [data, setData] = useState({
    status: null,
    practiceName: null,
    rows_per_page: 10,
    start: 0,
  });

  useEffect(() => {
    if (current_tab === "all_subscriptions") {
      dispatch(
        GetAllStripeSubscriptions({
          user_token: account.user_token,
          data,
        })
      );
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleClearFilters = () => {
    setData({
      status: null,
      practiceName: null,
      rows_per_page: 10,
      start: 0,
    });
    nameRef.current.value = "";
  };

  const handleBeforeClick = () => {
    if (data.start !== 0) {
      setData((prevState) => ({
        ...prevState,
        start: 0,
      }));
    }
  };
  const handleNextClick = () => {
    if (all_stripe_subscriptions_count > data.rows_per_page) {
      setData((prevState) => ({
        ...prevState,
        start: data.rows_per_page,
      }));
    }
  };

  const handleNameSearch = () => {
    setData((prevState) => ({
      ...prevState,
      practiceName: nameRef.current.value,
    }));
  };
  return (
    <div className="">
      {/* Filters */}
      <div className="row m-0">
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingLeft: "0px" }}
        >
          <div className="form-group mt-2">
            <label htmlFor="" className="form-label">
              Status
            </label>
            <FormControl fullWidth>
              <Select
                name="status"
                value={data.status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>All</MenuItem>
                <MenuItem value={"cancelled"}>CANCELLED</MenuItem>
                <MenuItem value={"active"}>ACTIVE</MenuItem>
                <MenuItem value={"trialing"}>TRIALING</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-12"
          style={{ paddingRight: "0px  " }}
        >
          <div className="form-group mt-2">
            <label className="form-label">Name</label>
            <div className="input-group mb-3 name_filter_group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter practice username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                ref={nameRef}
              ></input>
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={handleNameSearch}
              >
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mt-2 d-flex">
          <Button onClick={handleClearFilters} type="default">
            Clear All Filters
          </Button>
        </div>
      </div>
      <hr></hr>

      {/* Table */}
      <Box sx={{ p: 0, width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={GET_TABLE_COLUMNS(columnLabels)} />
              <TableBody>
                {all_stripe_subscriptions &&
                all_stripe_subscriptions.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={columnLabels.split(",").length}
                    >
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  all_stripe_subscriptions &&
                  all_stripe_subscriptions.length > 0 &&
                  all_stripe_subscriptions.map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.payment_id}>
                        <TableCell>
                          {item.subscription_id && item.subscription_id}
                        </TableCell>
                        <TableCell>
                          {item.user_name && item.user_name}
                        </TableCell>
                        <TableCell>
                          {item.amount && "$" + parseInt(item.amount) / 100}
                        </TableCell>
                        <TableCell>
                          {item.status && (
                            <span
                              className={
                                item.status === "active"
                                  ? "text-success "
                                  : "text-warning"
                              }
                              style={{ textTransform: "uppercase" }}
                            >
                              {item.status}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.current_period_start && (
                            <p style={{ margin: "0px" }}>
                              <b>Start date : </b>
                              {dayjs(item.current_period_start).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          )}
                          {item.current_period_start && (
                            <>
                              <b>End date : </b>
                              {dayjs(item.current_period_end).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.created_at &&
                            dayjs(item.created_at + "Z").format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={item.status !== "active"}
                            onClick={() => {
                              alert(item.subscription_id);
                            }}
                          >
                            Cancel
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableFooter
            start={data.start}
            rows_per_page={data.rows_per_page}
            total_results_count={all_stripe_subscriptions_count}
            handleRowCountChange={handleChange}
            handleBeforeClick={handleBeforeClick}
            handleNextClick={handleNextClick}
          ></EnhancedTableFooter>
        </Paper>
      </Box>
    </div>
  );
}
